import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { getCookieConsentValue } from "react-cookie-consent";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Auth0ProtectedRoute = ({ component, ...args }) => {
  let consentValue = getCookieConsentValue('auth')
  const history = useHistory()
   
  if (consentValue === 'false') {
     window.alert('Login feature uses 3rd Party Cookies. This feature has been disabled as you did not allow use of 3rd party Cookies.')
     return history.push('/')
  }

  return (
      <Route
        component={withAuthenticationRequired(component )}
        {...args}
      />
)};

export default Auth0ProtectedRoute

