import { createSlice } from "@reduxjs/toolkit"

const initialState = { isModalOpen : false, isSubMenuOpen:false, coords:'', activeChild:null }

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state) {
        state.isModalOpen = true
    },
    closeModal(state) {
        state.isModalOpen = false
    },
  }
})

export const { openModal, closeModal } = modal.actions

export default modal.reducer