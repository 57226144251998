import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from "react-overlays/Modal";
import styled from 'styled-components'
import { GrAddCircle } from 'react-icons/gr'
import { MdOutlineClose } from "react-icons/md";
import { isAndroid, isIOS, isMobile } from 'react-device-detect'

import { NoItems } from '../../../Components/dashboard/views/Towers';
import Filters from '../../../Components/filters/Filters'
import { Button } from '../../../Components/button/Button';
import { fetchProfiles } from '../../../Components/service/services.list.slice';
import ProductListingSkeleton from '../../../Components/skeletons/product-list';
import { fetchRoadsideProfiles } from '../../../Components/service/profiles.list.slice';
import ServicesList, { RoadsideAssistsList, ServicesListDesktop, RoadsideAssistsDesktopList } from '../../../Components/service/List';
import { LocationAlertContainer, LocationAlertText } from '../create-service/RoadsideAssist';
import { Loading } from '../../../Components/loader/loader';

const BtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
    align-items: center;
        @media (min-width: 750px) {
            display: none;
    }
` 

const SearchInfo = styled.div`
    align-items: center;
    margin-bottom: 15px;
        @media (min-width: 750px) {
            margin: 30px auto;
    }
`

const FormContainer = styled.form`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media (min-width: 750px){
        width: 350px;
    }
    @media (min-width: 1130px){
        width: 100%;
    }
`

export const SearchBarContainer = styled.div`
    min-width: 80%;
    max-width: 350px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: transparent;
    @media (min-width: 1130px) {
        margin-left: 20px;
        min-width: 300px;
        max-width: 300px;
    }
`

export const SearchBar = styled.input`
    background: transparent;
    border: none;
    padding: 10px;
    outline: none;
    height: 30px;
`

const SearchResultsTag = styled.p`
    font-weight: 400;
    font-size: 13px;
    margin-top: 18px;
    color: black;
    text-align: center;
`

export const Container = styled.div`
    background: rgb(118, 195, 212);
    padding: 5px;
    position: relative;
    margin-bottom: 1px;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
`

const ImageContainer = styled.div`
    text-align: center;
`

const Image = styled.img`
    width: 100px;
`

export const Text = styled.p`
    text-align: center;
    width: 100%;
    border: none;
    font-size: 15px;
    padding: 10px;
    font-size: x-small;
    font-weight: 600;

`

function Services() {
    const { loading, profiles, profilesError, initialFetch } = useSelector(state => state.profiles)
    const { loading: rloading, profiles: rprofiles, profilesError:rprofilesError } = useSelector(state => state.roadsideProfiles)
    const { selectedCategory: SC } = useSelector(state => state.selectedCategory)
    const { location, loading: locationLoading } = useSelector(state => state.location)
    const [state, setState] = useState({ selectedCategory: SC ? SC: 'Vehicle Maintenance' });
    const [isPopUpOpen, setIsPopUpOpen] = useState(true)
    // const [filters, setFilters] = useState({ rating: 0, price: 0, location: '', towingRate: 0,towingSelection: ''})
    const [filters, setFilters] = useState({ rating: 0, price: 0, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase(), towingRate: 0,towingSelection: ''})
    const [showModal, setShowModal] = useState(false);
    const [performedQuery, setPerformedSearchQuery] = useState(false)
    const [q, setQ] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()

    // console.log('locc', location?.textualInfo?.label?.split(',')[1].trim().toLowerCase())

    useEffect(() => {
        if (!profiles.length || Object.keys(location).length) {
            dispatch(fetchProfiles({...filters, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase() }))
        }
    },[location])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (state.selectedCategory === 'Vehicle Maintenance') {
            history.push(`/services?type=car-service&q=${q}`)
            dispatch(fetchProfiles({ q }))
        } else {
            history.push(`/services?type=roadside-assist&q=${q}`)
            dispatch(fetchRoadsideProfiles({ q }))
        } 
        setPerformedSearchQuery(true)   
    }
    

    const handleQChange = (e) => {
        setQ(e.target.value)
    }

    const renderBackdrop = (props) => <div className="fbackdrop" {...props} />;

    const redirect = () => {
        if (isAndroid) {
            window.location = 'https://play.google.com/store/apps';
        } else {
            window.location = 'https://apps.apple.com/us'
        }
    }
    
  return (
    <div>
        { 
        locationLoading ? <Loading loading={loading} label={'Fetching Location..'} />:
        !Object.keys(location).length ? <LocationAlertContainer>
            <LocationAlertText>This page needs location access to select Service Provider Profiles in your vicinity. To access Service Providers you need to allow location access.</LocationAlertText>
        </LocationAlertContainer>:
        <div className='services'>
        { isPopUpOpen && isMobile ? <Container>
            <Content>
                <MdOutlineClose style={{ position: 'absolute', top: 2, right: 2 }} color='red' onClick={() => setIsPopUpOpen(false)} cursor={'pointer'}  size={20}/>
                <Text>Roadside Assistance is exclusive only to the app. Download App to get all Features</Text>
            </Content>
            <ImageContainer onClick={redirect}>
                <Image src={`/${ isIOS ? 'app-store.png': 'play-store.webp'}`} alt="download-from-play-store" />
            </ImageContainer>
        </Container>: null }
        <Modal
            className="fmodal"
            show={showModal}
            onHide={() => setShowModal(false)}
            renderBackdrop={renderBackdrop}
            >
                <Filters selectedCategory={state.selectedCategory}
                         setSelectedCategory={setState}
                         setShowModal={setShowModal} 
                         setQ={setQ}
                         filters={filters}
                         setFilters={setFilters}
                         
                />
        </Modal>
        <BtnContainer >
            <p>Filters</p>
            <GrAddCircle cursor={'pointer'} size={12} onClick={() => {
                setShowModal(true)
            }} />
        </BtnContainer>
        <div className="services__wrapper">
            <div className="services__filters">
                <Filters selectedCategory={state.selectedCategory}
                         setSelectedCategory={setState}
                         setQ={setQ}
                         filters={filters}
                         setFilters={setFilters}
                />
            </div>
            <div className='services__content'>
                <SearchInfo>
                    <FormContainer onSubmit={handleSubmit} >
                        <SearchBarContainer>
                            <SearchBar key="search-bar"
                                    value={q}
                                    placeholder="Search for service"
                                    onChange={handleQChange}
                            />
                        </SearchBarContainer>
                        <Button onClick={handleSubmit} 
                                borderRadius='8px'
                                width='120px'
                                height='30px'
                                padding='0px'
                                color='white'
                                background='#76C3D4'
                                border='none'
                                >
                                    Search
                        </Button>
                    </FormContainer>
                    { (q && performedQuery) ? <SearchResultsTag>Results for: {q}</SearchResultsTag>: null }
                </SearchInfo>
                <div className="services__list-container">
                {
                    (loading || rloading) ?
                    Array.apply(null, Array(20)).map((val,idx) => {
                        return <ProductListingSkeleton key={idx} />
                    }) 
                    : (!profiles.length && state.selectedCategory === 'Vehicle Maintenance') ? <NoItems text='Oops No results :(' />:
                    (!rprofiles.length && state.selectedCategory === 'Roadside Assist') ? <NoItems text='Oops No results :(' />:
                    state.selectedCategory === 'Vehicle Maintenance' ? <>
                                                                            <ServicesListDesktop filters={filters}/>
                                                                            <ServicesList filters={filters}/>
                                                                        </> :
                    state.selectedCategory === 'Roadside Assist' ?  <>
                                                                        <RoadsideAssistsList filters={filters} />
                                                                        <RoadsideAssistsDesktopList filters={filters} />
                                                                    </> : null
                                                                    }
                </div>
            </div>
            
        </div>
    </div>}
    </div>
  )
}

export default Services