import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import styled from "styled-components";

import Service from "./Service";
import { paginationUpdate } from "./services.list.slice";
import { roadsidePaginationUpdate } from "./profiles.list.slice";
import ProductListingSkeleton from "../skeletons/product-list";

const ListContainer = styled.div`
  @media (min-width: 1000px) {
    display: none
  } 
`

const DesktopListContainer = styled.div`
    display: none;
    @media (min-width: 1000px) {
        display: block;
    }
`


const ServicesList = ({ filters }) => {
    const { loading, lastEvaluatedKey, profiles } = useSelector(state => state.profiles)
    const dispatch = useDispatch()
    
    const loadNextPage = () => {
        lastEvaluatedKey && dispatch(paginationUpdate({ lastEvaluatedKey, ...filters }))
    }

    const itemCount = lastEvaluatedKey ? profiles.length + 1 : profiles.length;
    const isItemLoaded = index => !lastEvaluatedKey || index < profiles.length;
    const isNextPageLoading = (loading && profiles.length) ? true : false
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    return (
        <ListContainer>
            <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={1}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (     
                            <FixedSizeList
                                className="listttt"
                                itemData={profiles}
                                onItemsRendered={onItemsRendered}
                                height={height}
                                itemCount={itemCount}
                                itemSize={350}
                                width={width}
                                ref={ref}
                               
                            >
                                {({data, index, style }) => {
                                    return (
                                        <div className="childddd" style={style}>
                                            {
                                            !isItemLoaded(index) ? <ProductListingSkeleton /> :
                                                        <Service profile={data[index]} type='car-service' />
                                            }
                                            
                                        </div>
                                    
                                    );
                                }}
                        </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </ListContainer>
    )
}

export const ServicesListDesktop = ({ filters }) => {
    const { loading, lastEvaluatedKey, profiles } = useSelector(state => state.profiles)
    const dispatch = useDispatch()
    
    const loadNextPage = () => {
        lastEvaluatedKey && dispatch(paginationUpdate({ lastEvaluatedKey, ...filters }))
    }

    const itemCount = lastEvaluatedKey ? profiles.length + 1 : profiles.length;
    const isItemLoaded = index => !lastEvaluatedKey || index < profiles.length;
    const isNextPageLoading = (loading && profiles.length) ? true : false
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    return (
        <DesktopListContainer>
            <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={1}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (     
                            <FixedSizeList
                                className="listttt"
                                itemData={profiles}
                                onItemsRendered={onItemsRendered}
                                height={height}
                                itemCount={itemCount}
                                itemSize={200}
                                width={width}
                                ref={ref}
                               
                            >
                                {({data, index, style }) => {
                                    return (
                                        <div className="childddd" style={style}>
                                            {
                                            !isItemLoaded(index) ? <ProductListingSkeleton /> :
                                                        <Service profile={data[index]} type='car-service' />
                                            }
                                            
                                        </div>
                                    
                                    );
                                }}
                        </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </DesktopListContainer>
    )
}

export const RoadsideAssistsList = ({ filters }) => {
    const { loading, profiles, lastEvaluatedKey } = useSelector(state => state.roadsideProfiles)
    const dispatch = useDispatch()
    
    const loadNextPage = () => {
        lastEvaluatedKey && dispatch(roadsidePaginationUpdate({ lastEvaluatedKey, ...filters }))
    }

    const itemCount = lastEvaluatedKey ? profiles.length + 1 : profiles.length;
    const isItemLoaded = index => !lastEvaluatedKey || index < profiles.length;
    const isNextPageLoading = (loading && profiles.length) ? true : false
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    return (
        <ListContainer>
            <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={1}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (     
                            <FixedSizeList
                                className="listttt"
                                itemData={profiles}
                                onItemsRendered={onItemsRendered}
                                height={height}
                                itemCount={itemCount}
                                itemSize={350}
                                width={width}
                                ref={ref}
                               
                            >
                                {({data, index, style }) => {
                                    return (
                                        <div className="childddd" style={style}>
                                            {
                                            !isItemLoaded(index) ? <ProductListingSkeleton /> :
                                                        <Service profile={data[index]} type='roadside-assist' />
                                            }
                                            
                                        </div>
                                    
                                    );
                                }}
                        </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </ListContainer>
    )
}

export const RoadsideAssistsDesktopList = ({ filters }) => {
    const { loading, profiles, lastEvaluatedKey } = useSelector(state => state.roadsideProfiles)
    const dispatch = useDispatch()
    
    const loadNextPage = () => {
        lastEvaluatedKey && dispatch(roadsidePaginationUpdate({ lastEvaluatedKey, ...filters }))
    }

    const itemCount = lastEvaluatedKey ? profiles.length + 1 : profiles.length;
    const isItemLoaded = index => !lastEvaluatedKey || index < profiles.length;
    const isNextPageLoading = (loading && profiles.length) ? true : false
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    return (
        <DesktopListContainer>
            <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={1}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (     
                            <FixedSizeList
                                className="listttt"
                                itemData={profiles}
                                onItemsRendered={onItemsRendered}
                                height={height}
                                itemCount={itemCount}
                                itemSize={200}
                                width={width}
                                ref={ref}
                               
                            >
                                {({data, index, style }) => {
                                    return (
                                        <div className="childddd" style={style}>
                                            {
                                            !isItemLoaded(index) ? <ProductListingSkeleton /> :
                                                        <Service profile={data[index]} type='roadside-assist' />
                                            }
                                            
                                        </div>
                                    
                                    );
                                }}
                        </FixedSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </DesktopListContainer>
    )
}

export default ServicesList