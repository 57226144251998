import React from 'react';
import styled from 'styled-components';

const Option = styled.p`
    display: flex;
    gap: 10px;
    margin: 30px 0;
    justify-content: center;
    @media (min-width: 900px) {
    justify-content: flex-start;
  }
`

const Bullet = styled.span`
    color: blue;
`

const Container = styled.div`
display: none;
@media (min-width: 900px) {
    display: block;
    margin-top: 60px;
  }
`

const MobileContainer = styled.div`
@media (min-width: 900px) {
    display: none;
  }
`

export const Highlighter = styled.p`
  display: inline;
  color: #009dfe;
  font-weight: bolder;
  font-size: 1.5rem;
`

const Text = styled.p`
    line-height: 2.6;
    font-size: 16px;
`

function ImageCardWithText({text,heading,image}) {
    return (
    <div className="card">
        
        <div className="card__text">
        <div className="card__container">
            <h1 className='card__h'>BRINGING <Highlighter>POWER</Highlighter> TO THE CONSUMERS</h1>
            <p className='card__p'>{text}</p>
            </div>
        </div>
        <div className="card__image">
            <img className='card__img' src={image} alt="card-icon" loading="lazy" />
        </div>
    </div>
    )
}

export const AboutUs = () => {
    return (
    <div className="card">
        
        <div className="card__text">
        <div className="card__container">
            <h1 className='card__h'>ABOUT <Highlighter>US</Highlighter></h1>
            <Text className='card__p'>At <strong style={{ fontSize: 16}}>Tap A Service</strong> we are driven by the passion to simplify car maintenance for everyone. Whether you need to book a car service, schedule routine maintenance or get emergency towing, we are here to make the process as easy and hassle-free as possible</Text>
            </div>
        </div>
        <div className="card__image">
            <img className='card__img' src='/about.png' alt="card-icon" loading="lazy" />
        </div>
    </div>
    )
}

export function WhoWeAreDesktop() {
    return (
    <Container>
        <div className="card">
            <div className="card__image">
                <img className='card__img' src='/who.png' alt="card-icon" loading="lazy" />
            </div>
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHO WE <Highlighter>ARE</Highlighter></h1>
                <Text><strong style={{ fontSize: 16}}>Tap A Service</strong> is an innovative, online-based platform that connects car owners with trusted Service Providers around them. With a few simple clicks, you can book vehicle services like epairs, oil changes, diagnostics and more. We partner with certified technicians, towing companies and other automative professionals o ensure you receive top-quality care when you need it.</Text>
                </div>
            </div>
        </div>
    </Container>
    )
}

export function WhoWeAreMobile() {
    return (
    <MobileContainer>
        <div className="card">
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHO WE <Highlighter>ARE</Highlighter></h1>
                <Text><strong style={{ fontSize: 16}}>Tap A Service</strong> is an innovative, online-based platform that connects car owners with trusted Service Providers around them. With a few simple clicks, you can book vehicle services like epairs, oil changes, diagnostics and more. We partner with certified technicians, towing companies and other automative professionals o ensure you receive top-quality care when you need it.</Text>
                </div>
            </div>
            <div className="card__image">
                <img className='card__img' src='/who.png' alt="card-icon" loading="lazy" />
            </div>
        </div>
    </MobileContainer>
    )
}

export function WhatWeOfferDesktop({text,heading,image}) {
    return (
    <Container>
        <div className="card">
            <div className="card__image">
                <img className='card__img' src={image} alt="card-icon" loading="lazy" />
            </div>
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHAT WE <Highlighter>OFFER</Highlighter></h1>
                <Option><Bullet>{'>>'}</Bullet> Get more client coverage thereby increasing your earnings</Option>
                <Option><Bullet>{'>>'}</Bullet> No more going back and forth, book from anywhere, anytime</Option>
                <Option><Bullet>{'>>'}</Bullet> Bringing the consumer and service provider closer together</Option>
                </div>
            </div>
        </div>
    </Container>
    )
}

export function WhatWeOfferMobile({text,heading,image}) {
    return (
    <MobileContainer>
        <div className="card">
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHAT WE <Highlighter>OFFER</Highlighter></h1>
                <Option><Bullet>{'>>'}</Bullet> Get more client coverage thereby increasing your earnings</Option>
                <Option><Bullet>{'>>'}</Bullet> No more going back and forth, book from anywhere, anytime</Option>
                <Option><Bullet>{'>>'}</Bullet> Bringing the consumer and service provider closer together</Option>
                </div>
            </div>
            <div className="card__image">
                <img className='card__img' src={image} alt="card-icon" loading="lazy" />
            </div>
        </div>
    </MobileContainer>
    )
}

export default ImageCardWithText;
