import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
    loading : false,
    reviews: [],
    reviewsError: '',
    lastEvaluatedKey: null,
}

const reviews = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    fetchReviewsRequest(state) {
      state.loading = true
    },
    fetchedReviewsSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.reviews = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMoreReviewsSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.reviews = [...state.reviews,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
    },
    fetchedReviewsError(state, action) {
      state.loading = false
      state.loadingMore = false
      state.reviewsError = action.payload
    },
  }
})

const { fetchReviewsRequest, fetchedReviewsSuccess,
                fetchedMoreReviewsSuccess, fetchedReviewsError  } = reviews.actions

export const fetchReviews = (companyEmail, type) => async (dispatch) => {
    try {
        dispatch(fetchReviewsRequest())
        const { data } = await customAxios.post('/reviews',{ companyEmail, type });
        const { Items, LastEvaluatedKey} = data
        dispatch(fetchedReviewsSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedReviewsError(error))
    }      
}

export const reviewsPaginationUpdate = (params) => async (dispatch) => {
    try {
        const { data } = await customAxios.post('/reviews',{...params });
        const { Items, LastEvaluatedKey } = data
        dispatch(fetchedMoreReviewsSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedReviewsError(error))
    }
}

export default reviews.reducer