import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  profile: {},
  profileError: ''
}

const initialProfileTypeState = {
  profileType: {},
}

const profileType = createSlice({
  name: 'profileType',
  initialState: initialProfileTypeState,
  reducers: {
    setProfileType(state, action) {
        state.profileType = action.payload
    },
    resetProfileType(state) {
      state.profileType = {}
    },
  }
})

export const { setProfileType, resetProfileType } = profileType.actions
export const profileTypeReducer = profileType.reducer

const dashboardProfile = createSlice({
  name: 'dashboardProfile',
  initialState,
  reducers: {
    fetchedDashboardProfileRequest(state) {
      state.loading = true
    },
    fetchedDashboardProfileSuccess(state, action) {
        state.loading = false
        state.profile = action.payload
    },
    fetchedDashboardProfileError(state, action) {
      state.loading = false
      state.profileError = action.payload
    },
  }
})

export const { fetchedDashboardProfileRequest, fetchedDashboardProfileSuccess, fetchedDashboardProfileError } = dashboardProfile.actions

export const fetchVehicleMaintenanceProfile = (email,token) => async (dispatch) => {
  dispatch(fetchedDashboardProfileRequest())
  try {
      const { data: { Items }} = await customAxios.get(`/dashboard/${encodeURIComponent(email)}`,{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      Items.length === 1  ? dispatch(fetchedDashboardProfileSuccess(Items[0])) : 
      dispatch(fetchedDashboardProfileSuccess({}))
  } catch(err)  {
      dispatch(fetchedDashboardProfileError({ message: 'Unauthorized' }))
  }      
}

export const fetchRoadsideAssistProfile = (companyEmail,token,companyID) => async (dispatch) => {
  dispatch(fetchedDashboardProfileRequest())
  try {
      const { data: { Items }} = await customAxios.get('/roadside-assist',{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              },
                                                                              params: {
                                                                                  companyEmail,
                                                                                  companyID
                                                                              }
                                                                          })
      Items.length === 1  ? dispatch(fetchedDashboardProfileSuccess(Items[0])) : 
      dispatch(fetchedDashboardProfileSuccess({}))
  } catch  {
      dispatch(fetchedDashboardProfileError({ message: 'Unauthorized' }))
  }      
}

export const profileRefetch = (email, companyID, type, accessToken) => async (dispatch) => {
      if (type === 'Vehicle Maintenance') {
          dispatch(fetchVehicleMaintenanceProfile(email, accessToken))
      } else {
          dispatch(fetchRoadsideAssistProfile(email, accessToken, companyID ))
      } 
}

export default dashboardProfile.reducer
