import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import ReviewsList from '../../Components/review/List'
import StarRatings from 'react-star-ratings';
import styled from 'styled-components'
import ReviewsListSkeleton from '../../Components/skeletons/reviews';
import { fetchReviews } from '../../Components/review/list.slice';

const Number = styled.p`
    font-size: 20px;
    font-weight: 600;
`

const RatingValue = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const RatingContainer = styled.div`
    padding: 10px 0
`

const Sub = styled.p`
    font-size: 20px;
`

function ReviewsPage() {
    const { loading } = useSelector(state => state.reviews)
    const { state: { reviewsCount, rating, type }} = useLocation()
    const { email } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchReviews(decodeURIComponent(email),type))
    },[])

  return (
    <>
   { loading ? <ReviewsListSkeleton /> : <div className='reviews'>
        <div className="reviews__header">
            <RatingContainer>
                <Sub>Overall Rating</Sub>
                <RatingValue>
                    <Number>{rating}</Number>
                    <StarRatings
                            rating={rating}
                            starRatedColor="#ffa534"
                            starDimension="20px"
                            starSpacing="3px"
                            numberOfStars={5}
                            name='rating'
                        />
                </RatingValue>
                <p>Based on {reviewsCount} Review(s)</p>
            </RatingContainer>
        </div>
        <p style={{ fontWeight: 'bold' }}>Reviews</p>
        <div className="reviews__list-container">
            
            <ReviewsList companyEmail={decodeURIComponent(email)} type={type} />

        </div>
    </div>
    }
    </>
  )
}

export default ReviewsPage