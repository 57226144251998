import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {LoginButton,LogoutButton} from '../button/Button'
import { auth } from '../../firebase/firebase';

const AuthenticationButton = ({ setSelectedService }) => {
  const { isAuthenticated } = useAuth0();
  const [fbuser, loading, error] = useAuthState(auth);

  return (isAuthenticated || fbuser) ? <LogoutButton /> : <LoginButton setSelectedService={setSelectedService} />
};

export default AuthenticationButton;
