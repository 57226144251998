import { useEffect } from "react";
import { css } from "@emotion/react";
// import RingLoader from "react-spinners/RingLoader";
import PropagateLoader from "react-spinners/PropagateLoader"
import BarLoader from 'react-spinners/BarLoader'
import { useAuth0 } from '@auth0/auth0-react';
// import MoonLoader from 'react-spinners/MoonLoader'

// export function Moon() {

//   const override = css`
//     display: block;
//     margin: 0 auto;
//   `;

//   return <MoonLoader color={'white'} loading={true} css={override} size={20} />
// }

// export function Ring({loading}) {

//   const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
//   `;

//   return (
//     <div className='ring-loader'>
//       <RingLoader color={'rgba(28, 34, 56, 1)'} loading={true} css={override} size={50} />
//     </div>
//   );
// }

export function InitScreen({loading}) {

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const text = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px'
  }

  const image = {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #00308F'
  }

  const label = {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#00308F'
  }

  return (
    <div className='loading'>
      <div style={text}>
        <img style={image} src="/logo.png" alt="" /><span style={label}>TapAService</span>
      </div>
      <BarLoader color={'rgba(28, 34, 56, 1)'} loading={true} css={override} size={50} />
    </div>
  );
}

export function Loading({loading,label}) {
  
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className='loading'>
      <BarLoader color={'rgba(28, 34, 56, 1)'} loading={loading} css={override} size={50} />
      { label ? <p className="loading__text">{label}</p> : null }
    </div>
  );
}

export function Uploading({text, color, textColor}) {
  
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    text-align: center;
  `;

  return (
    <div className='ring'>
      <p className="ring__text" style={textColor ? { color: textColor} : null} >{text}...</p>
      <PropagateLoader color={color} loading={true} css={override} size={10} />
    </div>
  );
}