import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import { updateProgressBar } from "./progress.slice"

const initialState = {
  uploading : false,
  uploaded: false,
  uploadFilesError: ''
}

const upload = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    fileUploadsRequest(state) {
      state.uploading = true
    },
    filesUploadedSuccess(state) {
      state.uploading = false
      state.uploaded = true
    },
    filesUploadedError(state, action) {
      state.uploading = false
      state.uploadFilesError = action.payload
    },
    filesUploadReset(state) {
      state.uploadFilesError = ''
      state.uploaded = false
      state.uploading = false
    },
  }
})

export const { fileUploadsRequest, filesUploadReset, filesUploadedError, filesUploadedSuccess } = upload.actions

const fileUpload = async (form,files,url,key,dispatch) => {
  const unresolvedUploadedFiles = files.map(async(file) => {
      let newKey = key.concat('/',file.name)
      form.set('Content-Type', file.type)
      form.set('key',newKey)
      form.set('file',file) 
      const result = await axios.post(url,form,{
                          headers:{'Content-Type': 'multipart/form-data'},
                          onUploadProgress: (progressEvent) => {
                              const { loaded, total, lengthComputable } = progressEvent;
                              let percentage
                              if (lengthComputable) {
                                  percentage = Math.round((loaded * 100) / total);
                                  dispatch(updateProgressBar({ name: file.name, percentage}))
                              }
                          }
                      });
      return result
  })
  return unresolvedUploadedFiles
}

export const uploadFiles = (form,files,url,key) => async (dispatch) => {
  try {
      dispatch(fileUploadsRequest())
      const unresolvedUploadedFiles = await fileUpload(form,files,url,key,dispatch) 
      await Promise.all(unresolvedUploadedFiles)       
      dispatch(filesUploadedSuccess())
  } catch (error) {
      dispatch(filesUploadedError(error))
  }
}


export default upload.reducer
