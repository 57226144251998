import React from "react";
import Skeleton from 'react-loading-skeleton'
import { Booking, Heading, BookingContainer, DetailItem } from "../../views/bookings/detail";

const BookingDetailSkeleton = () => {

    return (
        <div>
            <Booking>
                    <Heading>View your Booking Details</Heading>
                <BookingContainer >
                <section className="booking__block">
                    <h4>Booking Details</h4>
                    <DetailItem>
                            <p className='detail__subheading'>PROGRESS:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Appointment Date:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Service Tier:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Branch:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                </section>
                <section className="booking__block">
                    <h4>Personal Details</h4>
                    <DetailItem>
                            <p className='detail__subheading'>Full Name:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Mobile:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Address:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                </section>
                <section className="booking__block">
                    <h4>Vehicle Details</h4>
                    <DetailItem>
                            <p className='detail__subheading'>Make:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Model:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>VIN:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Year:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                    <DetailItem>
                            <p className='detail__subheading'>Mileage:</p>
                            <Skeleton width={250} height={20} />
                    </DetailItem>
                </section>
                
            </BookingContainer>
            </Booking>
        </div>
    )
}

export default BookingDetailSkeleton