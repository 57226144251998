import React from 'react'
import { useSelector } from 'react-redux'

const Parentdiv = {
    height: '30',
    width: '80%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
  }
  
  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900
  }

  const container = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: '80%'
  }

  const allFileUploads = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  }

  const block = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  }

const ProgressBar = ({ name, progress }) => {
    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#99ccff',
       borderRadius:40,
        textAlign: 'right'
      }
    
        
    return (
    <div style={container}>
        <h6>{name}</h6>
        <div style={block}>
            <div style={Parentdiv}>
                <div style={Childdiv}>
                    <span style={progresstext}>{`${progress}%`}</span>
                </div>
            </div>
            
        </div>
    </div>
    )
}

const AllFileUploads = ({media}) => {
    const { uploaded, uploadFilesError, uploading } = useSelector(state => state.uploadFiles)
    const { fileUploadsProgress } = useSelector(state => state.fileUploadsProgress)

    return (
        <div style={allFileUploads}>
            <p className='form__h'>File Uploads</p>
            <p style={{ fontWeight: 700 }}>Status: 
              <span style={{ fontWeight: 700, ...(uploaded && { color: 'green'}), ...(uploadFilesError && { color: 'red'}), ...(uploading && { color: 'blue'}) }}>  {uploading ? 'UPLOADING' : uploaded ? 'SUCCESS' : uploadFilesError ? 'UPLOAD ERROR' : 'NOT UPLOADING'}</span></p>
            {
                fileUploadsProgress?.map(fileUploadProgress => {
                    const { name, percentage } = fileUploadProgress
                    return (
                        <ProgressBar key={name} name={name} progress={percentage} />
                    )
                })
            }
        </div>
    )
}
  
export default AllFileUploads;