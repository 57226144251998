import React from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { BsArrowRightSquare} from 'react-icons/bs'

const Container = styled.div`
    display: inline-block;
    height: 50px;
    color: black;
    border-bottom: 1px solid;
    cursot: pointer;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 70%;
`

const Link = styled.div`
    display: inline-block;
    height: 100px;
    color: black;
    cursor: pointer;
`

const Heading = styled.h5`
font-size: 22px;
text-align: center;
`

function Info() {
    const { state } = useLocation()
    const history = useHistory()

  return (
    <div className='service-options profile-t__options'>
    <div className='profile-t__option profile-t__header'>
        <Heading>You should first make enquiries to get Appointment Date, Authorisation Code before making a booking</Heading> 
    </div>
    <Wrapper>
    <Container >
    <div className="service-options__item">
        <Link onClick={() => history.push('/chats',state)}  >    
                <div className='service-options__grp'>
                    <p className=''>Open Chat</p>
                    <BsArrowRightSquare />             
            </div>   
        </Link>
    </div>
    </Container>
    
    </Wrapper>
</div>
  )
}

export default Info