import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import { Button } from '../button/Button';
import { fetchProfiles } from '../service/services.list.slice';
import { fetchRoadsideProfiles } from '../service/profiles.list.slice';
import { setSelectedCategory } from '../service/services.list.slice';

const Input = styled.input`
  padding: 0;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  color: #bfbfbf;
  height: 30px;
  width: 200px;
  margin-top: 10px;
  outline: none;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
`;

const RatingValue = styled.p`
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    bottom: 1px;
    right: 0 ;
`

const CheckboxContainer = styled.div`
display: flex;
    gap: 5px;
`

function Filters({ setShowModal, selectedCategory, setSelectedCategory: setState, setQ, filters, setFilters }) {
    const { profiles } = useSelector(state => state.roadsideProfiles)
    const { location } = useSelector(state => state.location)
    const [checked, setIsChecked] = useState(null);
    const dispatch = useDispatch()
    const history = useHistory()

    // useEffect(() => {
    //     // setRating(0)
    //     // setLocation('')
    //     setFilters({...filters, rating: 0, location: ''})
    //     setQ('')
    //     if (selectedCategory === 'Roadside Assist' && !profiles.length) {
    //         dispatch(fetchRoadsideProfiles({ 
    //             location: "", 
    //             price: 0, rating: 0, towingRate: 0, towingSelection: "" }))
    //     }
    // },[selectedCategory])

    useEffect(() => {
        // setRating(0)
        // setLocation('')
        setFilters({...filters, rating: 0, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase()})
        setQ('')
        if (selectedCategory === 'Roadside Assist' && !profiles.length) {
            dispatch(fetchRoadsideProfiles({ 
                location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase(), 
                price: 0, rating: 0, towingRate: 0, towingSelection: "" }))
        }
    },[selectedCategory])

    const handleSelectionChange = (e) => {
        setState({ selectedCategory: e.target.id })
        dispatch(setSelectedCategory(e.target.id))
    }

    const handleTowingRateChange = (e) => {
        if (filters.towingSelection) {
            setFilters({...filters, towingRate: e.target.value})
        }
        
        // setTowingRate(e.target.value)
    }

    const handleLocationChange = (e) => {
        // setLocation(e.target.value)
        setFilters({...filters, location: e.target.value})
    }

    const fetchServiceProfiles = () => {
        setShowModal && setShowModal(false)
        const str = `/services?type=car-service&${filters.rating ? `rating=${filters.rating}&` : ''}${ filters.price ? `price=${filters.price}&` : ''}${ location?.textualInfo?.label?.split(',')[1].trim().toLowerCase() ? `location=${location?.textualInfo?.label?.split(',')[1].trim().toLowerCase()}` : ''}`
        const url = str[str.length -  1] === '&' ? str.slice(0,-1) : str
        history.push(url)
        setQ('')
        dispatch(fetchProfiles({ rating: filters.rating, price: filters.price, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase() }))
    }

    const fetchRoadsideAsssistProfiles = () => {
        setShowModal && setShowModal(false)
        const str = `/services?type=roadside-assist&${filters.rating ? `rating=${filters.rating}&` : ''}${ filters.towingRate ? `towingRate=${filters.towingRate}&towingSelection=${filters.towingSelection}` : ''}${ location?.textualInfo?.label?.split(',')[1].trim().toLowerCase() ? `location=${location?.textualInfo?.label?.split(',')[1].trim().toLowerCase()}` : ''}`
        const url = str[str.length -  1] === '&' ? str.slice(0,-1) : str
        history.push(url)
        setQ('')
        dispatch(fetchRoadsideProfiles({ rating: filters.rating, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase(), towingRate: filters.towingRate, towingSelection: filters.towingSelection }))
    }

    const handleTowRateChange = (e) => {
        const { value } = e.target
        setIsChecked(value)
        setFilters({...filters, towingSelection: value })
    }

  return (
    <div className='filter filter__modal'>
        <p className='filter__title'>Filters</p>
        <section className='filter__section'>
            <p className='filter__heading'>Category</p>
            <div className='filter__selection-container'>
                <div>
                    <span className={
                        `filter__selection ${selectedCategory === 'Vehicle Maintenance' ? 'filter__selection-selected' : null }`
                        }
                        id="Vehicle Maintenance"
                        onClick={handleSelectionChange}
                        >
                        Vehicle Maintenance
                    </span>
                </div>
                <div>
                    <span className={
                        `filter__selection ${selectedCategory === 'Roadside Assist' ? 'filter__selection-selected' : null }`
                        }
                        id="Roadside Assist"
                        onClick={handleSelectionChange}
                        >
                        Roadside Assist
                    </span>
                </div>
            </div>
        </section>
        <section style={{ position: 'relative'}} className='filter__section'>
            <p className='filter__heading'>Rating</p>
            
            <div >
                <ReactSlider className="slider" 
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            step={0.1}
                            max={5} 
                            defaultValue={0} 
                            onChange={(val) => setFilters({...filters, rating: val })} 
                />  
                <RatingValue>{filters.rating}</RatingValue>  
            </div>
        </section>
        <div >
            { selectedCategory === 'Vehicle Maintenance' ? (
                <section style={{ position: 'relative'}} className='filter__section'>
                <p className='filter__heading'>Service Rate (ZAR/hr)</p>
                
                <div >
                    <ReactSlider className="slider" 
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                step={10}
                                max={1000} 
                                defaultValue={50} 
                                onChange={(val) => setFilters({...filters, price: val })} 
                    />  
                    <RatingValue>{filters.price}</RatingValue>  
                </div>
            </section>
                ) : null }
            { selectedCategory === 'Roadside Assist' ? (
                <section className='filter__section'>
                    <p className='filter__heading'>Max Towing Rate (ZAR/hr)</p>
                    <CheckboxContainer>
                        <input type="checkbox" value="flatBed" onChange={handleTowRateChange} checked={checked === 'flatBed'} />Flat Bed
                    </CheckboxContainer>
                    <CheckboxContainer>
                        <input type="checkbox" value="wheelLift" onChange={handleTowRateChange}  checked={checked === 'wheelLift'} />Wheel Lift
                    </CheckboxContainer>
                    <CheckboxContainer>
                        <input type="checkbox" value="hookChain" onChange={handleTowRateChange} checked={checked === 'hookChain'} />Hook & Chain
                    </CheckboxContainer>
                    <Input value={filters.towingRate} onChange={handleTowingRateChange} />
                </section>
                ) : null }
            <section className='filter__section'>
                <p className='filter__heading'>Location</p>
                <Input placeholder='johannesburg' value={location?.textualInfo?.label?.split(',')[1].trim().toLowerCase()} onChange={handleLocationChange} />
            </section>
        </div>
        <div className='filter__btns-container'>
            <Button borderRadius='8px'
                    height='30px'
                    width='120px'
                    padding='0'
                    onClick={() => {
                        setFilters({ rating: 0, price: 0, location: location?.textualInfo?.label?.split(',')[1].trim().toLowerCase(), towingRate: '',towingSelection: ''})
                        setIsChecked(null)
                    }}
                    >Clear</Button>
            <Button background='#76C3D4'
                    borderRadius='8px'
                    border='none'
                    height='30px' 
                    color='white'
                    width='120px'
                    padding='0'
                    onClick={() => {
                        if (selectedCategory === 'Vehicle Maintenance') {
                            fetchServiceProfiles()
                        } else {
                            fetchRoadsideAsssistProfiles()
                        }
                    }}>
                        Apply
            </Button>
        </div>
    </div>
  )
}

export default Filters