import { Geo } from 'aws-amplify'; 

export const getSearchOptionsWithSearchAreaConstraints = (countryCode) => ({
  countries: [countryCode], 
  maxResults: 1, 
})

// export const searchOptionsWithSearchAreaConstraints = {
//   countries: ["ZAF"], 
//   maxResults: 1, 
// }

const getSearchOptionsForAutocompletion = (countryCode) => ({
  // "ZAF"
  countries: [countryCode],
  maxResults: 10
})

export const textSearch = async (text, countryCode) => {
  console.log('cc', countryCode)
  let results = await Geo.searchByText(text,getSearchOptionsForAutocompletion(countryCode))
  return results
}

export const reverseGeoCode = async (coords) => {
  let results = await Geo.searchByCoordinates(coords)
  return results
}

export const geocode = async(location, countryCode) => {
  // const coordsObject = []
  // let res
  
  const unresolved = location.map(async(item) => {
    let res = await Geo.searchByText(item,getSearchOptionsWithSearchAreaConstraints(countryCode))
    return { coordinates :res[0].geometry.point, address:item }
  })
  let coordsObject = await Promise.all(unresolved)

  // for (let i = 0; i < location.length; i++) {
  //   const value = location[i]
  //   res = await Geo.searchByText(value,getSearchOptionsWithSearchAreaConstraints(countryCode))
  //   coordsObject.push({coordinates :res[0].geometry.point,address:value })
  // }
  return coordsObject
}
