import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  metrics: {},
  metricsError: ''
}

const dashboardMetrics = createSlice({
  name: 'dashboardMetrics',
  initialState,
  reducers: {
    fetchedDashboardMetricsRequest(state) {
      state.loading = true
    },
    fetchedDashboardMetricsSuccess(state, action) {
        state.loading = false
        state.metrics = action.payload
    },
    fetchedDashboardMetricsError(state, action) {
      state.loading = false
      state.metricsError = action.payload
    },
  }
})

export const { fetchedDashboardMetricsRequest, fetchedDashboardMetricsSuccess, fetchedDashboardMetricsError } = dashboardMetrics.actions

export const fetchVehicleMaintenanceMetrics = (email,token) => async (dispatch) => {
  dispatch(fetchedDashboardMetricsRequest())
  try {
      const {data} = await customAxios.post('/analytics/vehicle-maintenance',{ companyEmail: email },  { headers: {
                                                                                      Authorization: `Bearer ${token}`
                                                                                      }})                                                                              
      dispatch(fetchedDashboardMetricsSuccess(data))
  } catch  {
      dispatch(fetchedDashboardMetricsError({ message: 'Unauthorized' }))
  }      
}

export const fetchRoadsideAssistMetrics = (companyID,id,token) => async (dispatch) => {
  dispatch(fetchedDashboardMetricsRequest())
  try {
      const {data} = await customAxios.post('/roadside-assist/stats',{ companyID }, { params: { id },
                                                                                      headers: {
                                                                                          Authorization: `Bearer ${token}`
                                                                                          }})                                                                              
      dispatch(fetchedDashboardMetricsSuccess(data))
  } catch  {
      dispatch(fetchedDashboardMetricsError({ message: 'Unauthorized' }))
  }      
}

export default dashboardMetrics.reducer
