import React, { useContext } from 'react'
import styled from 'styled-components'
import { OnlineStatus } from './Context'

const Container = styled.div`
    background: red;
    color: white;
    text-align: center; 
`

function Offline() {
    const online = useContext(OnlineStatus)

  return (
    <>
        {
            !online ? <Container>No internet Connection</Container> : null
        }
    </>
    
  )
}

export default Offline