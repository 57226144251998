import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  fetching : true,
  existingSubscription: null,
  existingSubscriptionError: ''
}

const existingSubscription = createSlice({
  name: 'existingSubscription',
  initialState,
  reducers: {
    fetchExistingSubscriptionRequest(state) {
      state.fetching = true
    },
    fetchExistingSubscriptionSuccess(state, action) {
        state.fetching = false
        state.existingSubscription = action.payload
    },
    fetchExistingSubscriptionError(state, action) {
      state.fetching = false
      state.existingSubscription = action.payload
    },
  }
})

export const { fetchExistingSubscriptionRequest, fetchExistingSubscriptionSuccess, fetchExistingSubscriptionError } = existingSubscription.actions

export const fetchExistingSubscription = (postData) => async (dispatch) => {
  dispatch(fetchExistingSubscriptionRequest())
  try {
      const { data: { Items } } = await customAxios.post('/subscriptions/find-existing-entry', { ...postData })
      if (Items.length) {
        dispatch(fetchExistingSubscriptionSuccess(Items[0]))
      } else {
        dispatch(fetchExistingSubscriptionSuccess(null))
      }
  } catch (error) {
      dispatch(fetchExistingSubscriptionError(error))
  }      
}

export default existingSubscription.reducer
