import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://2vk14bqot6.execute-api.us-east-1.amazonaws.com/dev'
  }),
  endpoints: build => ({
    createAccount: build.mutation({
      query: postData => ({
          url: '/account',
          method: 'POST',
          body: postData,
          headers: {
              'Authorization': 'application/json'
          }
        })
      }),
    assignRole: build.mutation({
      query: postData => ({
          url: '/assign-role',
          method: 'POST',
          body: postData,
        })
      }),
    createBooking: build.mutation({
        query: postData => ({
            url: '/bookings',
            method: 'POST',
            body: postData,
            headers: {
                'Authorization': `Bearer ${postData.token}`
            }
          })
        }),
    updateBooking: build.mutation({
        query: postData => ({
            url: '/booking/update',
            method: 'POST',
            body: postData,
            headers: {
                Authorization: `Bearer ${postData.token}`
            }
          })
        }),
    getReview: build.mutation({
        query: data => ({
            url: `/review/${encodeURIComponent(data.email)}`,
            params: { companyEmail: data.companyEmail }
            })
        }),
  })
})

export const { useCreateBookingMutation, useGetReviewMutation, useUpdateBookingMutation,
              useCreateAccountMutation, useAssignRoleMutation } = api