/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d6a69260-cbbf-4125-b0e4-da87b18a2aa7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FqXAHtvZw",
    "aws_user_pools_web_client_id": "86gtf5r8s64ou2um7dcirq9lq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://rfgjiqkgc5afhd5c674eiaqanm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "map60ae9ddf-dev": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map60ae9ddf-dev"
            },
            "search_indices": {
                "items": [
                    "placeindexc7a9e495-dev"
                ],
                "default": "placeindexc7a9e495-dev"
            }
        }
    }
};


export default awsmobile;
