import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  authCheck: {
      firebase: false,
      authZero: { complete: false, isServiceProvider: false },
  }
}

const authCheck = createSlice({
  name: 'authCheck',
  initialState,
  reducers: {
    updateAuth0Check(state, action) {
      state.authCheck.authZero = action.payload
    },
    updateFirebaseCheck(state, action) {
        state.authCheck.firebase = action.payload
    }
  }
})

export const { updateAuth0Check, updateFirebaseCheck, updateIsServiceProviderCheck, checkComplete } = authCheck.actions


export default authCheck.reducer
