import React from 'react'
import styled from 'styled-components'

import Footer from '../../Components/footer/footer'
import { AboutUs } from '../../Components/imageCardWithText/imageCardWithText'
import { WhoWeAreDesktop, WhoWeAreMobile, Highlighter } from '../../Components/imageCardWithText/imageCardWithText'

const options = [
    {
        heading: 'Service Bookings',
        text: "Need a tune-up or repairs? Schedule an appointment with our network of certified mechanics. You'll find experts who can handle everything from routine check-ups to omplex repairs.",
        image: '/book-online.png'
    },
    {
        heading: 'Car Maintenance',
        text: "Keep your vehicle in top shape with our maintenance scheduling services. Schedule services for routine and in-depth maintenance with ease from the comfort of your home",
        image: '/maintenance.png'
    },
    {
        heading: 'Emergency Towing',
        text: "Stranded on the road? Our reliable Towing partners are available 24/7 to get you back on track quickly and safely.",
        image: '/towing.png'
    },
    {
        heading: 'Become a Service Provider',
        text: "If you are a Certified Automotive Service and a Certified Towing Service looking to grow your business, we invite you to join our Platform. Connect with car owners in need of your expertise and expand your client base effortlessly",
        image: '/provider.png'
    }
]

const Wrapper = styled.div`
    @media (min-width: 800px) {
        display: flex;
        margin-top: 50px;
        justify-content: space-evenly;
        gap: 5px;
    }
`

const Container = styled.div`
    width: 80%;
    margin: 40px auto;
`

const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 45px auto;
     @media (min-width: 900px) {
        margin: 0;
        width: 100%;
    }
`

const Image = styled.img`
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
`

const Text = styled.p`
    text-align: center;
    max-width: 300px;
`

const Statement = styled.p`
    padding: 50px;
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 18px;
    line-height: 1.5;
    border-top: solid;
`

function About() {
  return (
    <div>
        <AboutUs />
        <WhoWeAreDesktop />
        <WhoWeAreMobile />
        <Container>
            <h1 style={{ textAlign: 'center' }} className='card__h'>WHAT WE <Highlighter>OFFER</Highlighter></h1>
            <Wrapper>
                {
                    options.map(el => {
                        const { heading, text, image } = el
                        return (
                            <Option>
                                <Image src={image} alt="" />
                                <h4>{heading}</h4>
                                <Text>{text}</Text>
                            </Option>
                        )
                    })
                }
            </Wrapper>
        </Container>
        <Statement>At Tap A Service, we believe that mantaining your vehicle should be as smooth as driving it. Whether you are a car owner looking for reliable services or a professional seeking new clients, we are your one-stop solution for all things automotive. </Statement>
        <Footer />
    </div>
  )
}

export default About