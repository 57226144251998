import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : true,
  subscription: null,
  subscriptionError: ''
}

const initialSubsState = {
  loading : true,
  subscriptions: null,
  subscriptionsError: ''
}

const servicesSubscription = createSlice({
  name: 'servicesSubscription',
  initialState,
  reducers: {
    fetchServicesSubscriptionRequest(state) {
      state.loading = true
    },
    fetchServicesSubscriptionSuccess(state, action) {
        state.loading = false
        state.subscription = action.payload
    },
    fetchServicesSubscriptionError(state, action) {
      state.loading = false
      state.subscriptionError = action.payload
    },
  }
})

const subscriptions = createSlice({
  name: 'subscriptions',
  initialState: initialSubsState,
  reducers: {
    fetchSubscriptionsRequest(state) {
      state.loading = true
    },
    fetchSubscriptionsSuccess(state, action) {
        state.loading = false
        state.subscriptions = action.payload
    },
    fetchSubscriptionsError(state, action) {
      state.loading = false
      state.subscriptionsError = action.payload
    },
  }
})

export const { fetchSubscriptionsRequest, fetchSubscriptionsSuccess, fetchSubscriptionsError } = subscriptions.actions
export const { fetchServicesSubscriptionRequest, fetchServicesSubscriptionSuccess, fetchServicesSubscriptionError } = servicesSubscription.actions

export const fetchServicesSubscription = (type) => async (dispatch) => {
  dispatch(fetchServicesSubscriptionRequest())
  try {
      let res
      if (type === 'Vehicle Maintenance') {
        res = await customAxios.get('/subscriptions/services')
      } else if (type === 'Roadside Assist') {
        res = await customAxios.get('/subscriptions/towing')
      }
      dispatch(fetchServicesSubscriptionSuccess(res.data.Items[0]))
  } catch (error) {
      dispatch(fetchServicesSubscriptionError(error))
  }      
}

export const fetchSubscriptions = () => async (dispatch) => {
  dispatch(fetchSubscriptionsRequest())
  try {
      let { data: { Items: Service }} = await customAxios.get('/subscriptions/services')
      let { data: { Items: Towing}} = await customAxios.get('/subscriptions/towing')
      dispatch(fetchSubscriptionsSuccess({ towing: Towing[0], services: Service[0]}))
  } catch (error) {
      dispatch(fetchSubscriptionsError(error))
  }      
}


export const subscriptionsReducer = subscriptions.reducer
export default servicesSubscription.reducer
