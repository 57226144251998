import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';

export default function FirebaseProtectedRoute(props){
    const [user, loading, error] = useAuthState(auth);
    const { search, state } = useLocation()
    const history = useHistory()
    const { token, amount, transactionReference, bankReference, paymentOption, clientName, companyID } = queryString.parse(search)
    
    if (user==null) {
        history.push(`/login?next=${props.path}${token ? `&token=${token}`: ''}${amount ? `&amount=${amount}&bankReference=${bankReference}&transactionReference=${transactionReference}&paymentOption=${paymentOption}&clientName=${clientName}&companyID=${companyID}`: ''}`,{ ...(state && state)})
        return null
    }
    else {
        return (
            <Route exact path={props.path}>
                {props.children}
            </Route>
            )
    }
}