import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  profile: {},
  profileError: ''
}

const serviceDetail = createSlice({
  name: 'serviceDetail',
  initialState,
  reducers: {
    fetchServiceDetailRequest(state) {
      state.loading = true
    },
    fetchServiceDetailSuccess(state, action) {
        state.loading = false
        state.profile = action.payload
    },
    fetchServiceDetailError(state, action) {
      state.loading = false
      state.profileError = action.payload
    },
    fetchServiceDetailReset(state) {
      state.loading = false
      state.profileError = ''
      state.profile = {}
    },
  }
})

export const { fetchServiceDetailRequest, fetchServiceDetailSuccess, fetchServiceDetailError, fetchServiceDetailReset } = serviceDetail.actions

export const fetchProfileDetail = (email) => async (dispatch) => {
  dispatch(fetchServiceDetailRequest())
  try {
      const {data} = await customAxios.get(`/services/${encodeURIComponent(email)}`)
      dispatch(fetchServiceDetailSuccess(data.Items[0]))
  } catch (error) {
      dispatch(fetchServiceDetailError(error))
  }      
}

export default serviceDetail.reducer
