import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  creating : false,
  created: false,
  createTowerProfileError: ''
}

const createTower = createSlice({
  name: 'createTower',
  initialState,
  reducers: {
    createTowerRequest(state) {
      state.creating = true
    },
    createTowerSuccess(state, action) {
        state.creating = false
        state.created = true
    },
    createTowerError(state, action) {
      state.creating = false
      state.createTowerProfileError = action.payload
    },
    createTowerReset(state) {
      state.creating = false
      state.createTowerProfileError = ''
      state.created = null
    },
  }
})

export const { createTowerRequest, createTowerSuccess, createTowerError, createTowerReset } = createTower.actions

export const createTowerProfile = (data,id,token) => async (dispatch) => {
    
  try {
      dispatch(createTowerRequest())
      await customAxios.post('/towers',data,{ params: { id },
                                              headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(createTowerSuccess())
  } catch (error) {
      dispatch(createTowerError(error))
  }
}

export default createTower.reducer
