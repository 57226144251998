import { createSlice } from "@reduxjs/toolkit"

const initialState = { isSubMenuOpen:false, coords:'', menu: '' }

const submenu = createSlice({
  name: 'submenu',
  initialState,
  reducers: {
    openSubMenu(state, action) {
        const { coords, menu } = action.payload
        state.isSubMenuOpen = true
        state.coords = coords
        state.menu = menu
    },
    closeSubMenu(state) {
        state.isSubMenuOpen = false
        state.coords = ''
        state.menu = ''
    },
  }
})

export const { openSubMenu, closeSubMenu } = submenu.actions

export default submenu.reducer