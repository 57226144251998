import React, { Suspense, lazy, useEffect } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useDispatch } from 'react-redux';

import { Loading } from './Components/loader/loader';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import Auth0ProtectedRoute from './auth/protected-route';
import FirebaseProtectedRoute from './firebase/protected-route';
import NavBar from './Components/navBar/NavBar';
import Home from './views/home/home';
import SignUp from './views/signup/SignUp';
import SignupSuccess from './views/signup/Success';
import CreateService from './views/services/create-service/CreateService';
import CreateRoadsideAssistService from './views/services/create-service/RoadsideAssist'
import CreateVehicleService from './views/services/create-service/VehicleMaintenance'
import Services from './views/services/list/Services';
import ServiceDetail from './views/services/detail/ServiceDetail';
import { StepperProvider } from './Components/stepper/context';
import OnlineStatusProvider from './Components/offline/Context';
import { FirebaseContextProvider } from './firebase/firebase.context';
import { ChatBadgeContextProvider } from './Components/chat/badge.context';
import ReviewsList from './views/reviews/ReviewsList';
import Offline from './Components/offline/Offline';
import CreateBooking from './views/bookings/create';
import BookingDetail from './views/bookings/detail';
import Chats from './views/chats/Chats';
import Login from './views/login/login';
import Info from './views/bookings/info';
import Pay from './views/payments/pay';
import PaymentSuccess from './views/payments/pay-success';
import PaymentCancelled from './views/payments/pay-cancel';
import PaymentError from './views/payments/pay-error';
import Account from './firebase/acount';
import BookingCheckoutSuccess from './views/bookings/checkout';
import PrivacyPolicy from './views/privacy-policy/privacy-policy';
import CookiePolicy from './views/cookie-policy/cookie-policy';
import TermsOfUse from './views/terms/terms-of-use';
import Subscriptions from './views/subscriptions/subscriptions';
import About from './views/about-us/about-us';
import Amplify from 'aws-amplify';
import { reverseGeoCode } from './utils/map';
import { locationSuccess, loadingLocation, locationError } from './Components/list/location.slice';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './sass/main.scss';
import awsconfig from './aws-exports';

import CreateSubscription from './views/services/create-service/CreateSubscription';

const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Dashboard'))

Amplify.configure(awsconfig)

function App() {
  // useEffect(() => {
  //   import('aws-amplify').then(module => {
  //     module.Amplify.configure(awsconfig)  
  //   })
  // },[])

  const dispatch = useDispatch()

    useEffect(() => {
        const getTextLocation = async() => {
            try {
              dispatch(loadingLocation())
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  async(position) => {
                    const { latitude, longitude } = position.coords;
                    let res = await reverseGeoCode([longitude, latitude])
                    dispatch(locationSuccess({ longitude, latitude, textualInfo: res }))
                  },
                  (error) => {
                    dispatch(locationError())
                  }
              );
              } else {
                dispatch(locationError())
                alert('Geolocation is not supported by this browser. You will not be able to access some Pages as they need Location Access to function properly.')
              }
            } catch (error) {
              dispatch(locationError())
              alert('GeoLocation Error: Failed to get your reverse geocoded device location. You will not be able to access some Pages as they need Location Access to function properly.')
            }
          }

          getTextLocation()
    },[])
  
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <FirebaseContextProvider>
          <ChatBadgeContextProvider>
            <OnlineStatusProvider>
              <StepperProvider>
                    <div className='App' >
                      <CookieConsent
                          location="bottom"
                          buttonText="OK"
                          
                          cookieName="auth"
                          acceptOnScroll
                          acceptOnScrollPercentage={80}
                          declineButtonText="Decline"
                          
                          enableDeclineButton
                          style={{ background: "#2B373B" }}
                          buttonStyle={{ color: "white", fontSize: "13px", backgroundColor: 'green' }}
                          declineButtonStyle={{ fontSize: "14px" }}
                          expires={150}
                        >
                          This website uses 3rd party cookies which are necessary for your user authentication. We also use 3rd party cookies for analytics to analyze our traffic. Disabling these cookies will result in you being unable to log in. By clicking "OK" you agree to our Cookie policy. <a rel='noreferrer' target='_blank' href='https://tapaservice.com/cookie-policy' style={{ textDecoration: 'underline', cursor: 'pointer'}}>Cookie Policy</a>.
                        </CookieConsent>
                        <Offline />
                        <NavBar />
                        <Switch>
                          <Route path="/" exact ><Home/></Route>
                          <Route path="/login" exact ><Login/></Route>
                          <Route path="/booking/checkout-success" exact ><BookingCheckoutSuccess/></Route>
                          <Route path="/pay" exact ><Pay/></Route>
                          <Route path="/payment-error" exact ><PaymentError/></Route>
                          <Route path="/payment-cancel" exact ><PaymentCancelled/></Route>
                          <Route path="/payment-success" exact ><PaymentSuccess/></Route>
                          <Route path="/signup" exact ><SignUp/></Route>
                          <Route path="/services" exact ><Services/></Route>
                          <Route path="/service/:email" exact ><ServiceDetail/></Route>
                          <Route path="/service/:email/reviews" exact ><ReviewsList/></Route>
                          <Route path="/signup/success" exact ><SignupSuccess/></Route>
                          <Route path="/privacy-policy" exact ><PrivacyPolicy/></Route>
                          <Route path="/cookie-policy" exact ><CookiePolicy/></Route>
                          <Route path="/terms-of-use" exact ><TermsOfUse/></Route>
                          <Route path="/about-us" exact ><About/></Route>
                          <Route path="/subscriptions" exact ><Subscriptions/></Route>
                          {/* <FirebaseProtectedRoute path="/account/delete" exact ><DeleteAccount/></FirebaseProtectedRoute> */}
                          <FirebaseProtectedRoute path="/account" exact ><Account /></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking" exact ><BookingDetail/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking/create" exact ><CreateBooking/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking/create/info" exact ><Info/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/chats" exact ><Chats/></FirebaseProtectedRoute>
                          {/* <FirebaseProtectedRoute path="/services/booking" exact ><CreateBooking /></FirebaseProtectedRoute> */}
                          <Auth0ProtectedRoute path="/services/create" exact component={CreateService} />
                          <Auth0ProtectedRoute path="/sub/create" exact component={CreateSubscription} />
                          <Auth0ProtectedRoute path="/services/create/car-maintenance" exact component={CreateVehicleService} />
                          <Auth0ProtectedRoute path="/services/create/roadside-assist" exact component={CreateRoadsideAssistService} />
                          <Suspense fallback={<Loading loading={true} />} >
                            <Auth0ProtectedRoute path="/dashboard/:id" exact component={Dashboard} />
                          </Suspense>
                        </Switch>
                    </div>
              </StepperProvider>
            </OnlineStatusProvider>
          </ChatBadgeContextProvider>
        </FirebaseContextProvider>
    </Auth0ProviderWithHistory>
    </Router>
  )
}

export default App
