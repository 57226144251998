import React, { useEffect } from 'react'
import styled from 'styled-components'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

export const Container = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

export const Text = styled.p`
    font-size: 20px;
`

function PaymentSuccess() {
  const location = useLocation()
  const history = useHistory()

  const { Optional1, Optional2 } = queryString.parse(location.search)
  
  useEffect(() => {
    if (Optional1 && Optional2) {
      history.push('/booking/checkout-success')
    }
  },[])
  
  return (
    <Container>
        <BsFillCheckCircleFill color='green' size={50} />
        <Text>Payment Successful</Text> 
    </Container>
  )
}

export default PaymentSuccess