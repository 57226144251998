import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  subscription: {},
  subscriptionError: ''
}

const subscription = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    fetchedSubscriptionRequest(state) {
      state.loading = true
    },
    fetchedSubscriptionSuccess(state, action) {
        state.loading = false
        state.subscription = action.payload
    },
    fetchedSubscriptionError(state, action) {
      state.loading = false
      state.subscriptionError = action.payload
    },
  }
})

export const { fetchedSubscriptionRequest, fetchedSubscriptionSuccess, fetchedSubscriptionError } = subscription.actions

export const fetchSubscription = (postData, token) => async (dispatch) => {
  dispatch(fetchedSubscriptionRequest())
  try {
      const { data } = await customAxios.post('/subscriptions/subscription',{ ...postData },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      dispatch(fetchedSubscriptionSuccess(data))
  } catch(err)  {
      console.log(err);
      dispatch(fetchedSubscriptionError({ message: 'Unauthorized' }))
  }      
}



export default subscription.reducer
