import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { reauthenticateWithPopup, GoogleAuthProvider } from 'firebase/auth'

import { MdManageAccounts } from "react-icons/md";
import styled from 'styled-components'
import useCollapse from 'react-collapsed'

import { auth } from '../../../firebase/firebase';
import { Button } from '../../button/Button';
import { Uploading } from '../../loader/loader';
import { submitAccountDeletionRequest, createAccountDeletionReset } from '../redux/account.delete.slice';

export const AttributeContainer = styled.div`
    margin: 20px auto;
`

export const AttributeField = styled.p`
    font-weight: 600;
`

const DeleteNote = styled.p`
    padding-left: 10px;
    margin: 10px auto;
    color: red;
`

const googleProvider = new GoogleAuthProvider();

function ManageAccount({ isClientAccount }) {
    const { creating, created, createAccountDeletionError } = useSelector(state => state.createAccountDeletion)
    const { booking } = useSelector(state => state.bookingDetail)
    const [fbuser, loading, error] = useAuthState(auth);
    const { metadata } = useSelector(state => state.metadata)
    const [isDeleteExpanded, setIsDeleteExpanded] = useState(false)
    const [isReAuthenticated, setIsReAuthenticated] = useState(false)
    const [deleteUserAccount, setDeleteUserAccount] = useState({ deleting: false, deleted: false, error: '' })
    const [btnDisable, setBtnDisable] = useState(false)
    const [text, setText] = useState('')
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()

    const { email, created_at, email_verified } = metadata
    const { getCollapseProps, getToggleProps } = useCollapse(isDeleteExpanded)

    const handleDeleteSubmit = async() => {
        try {
            if (isClientAccount && !booking) {
                await auth.currentUser.delete()
                return;
            } else if (isClientAccount && booking) {
                return window.alert('You can not delete your Account as you have an ongoing Booking.')
            }
        } catch (error) {
           try {
                await reauthenticateWithPopup(auth.currentUser, googleProvider)
                return setIsReAuthenticated(true)
           } catch (error) {
            setDeleteUserAccount({...deleteUserAccount, deleting: false, deleted: false })
            return window.alert('Error deleting User Account. Try again later.')
           }
        }
        try {
            setBtnDisable(true)
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            const data = { email }
            dispatch(submitAccountDeletionRequest(data, user.sub, accessToken))
        } catch (error) {
          setBtnDisable(false)
        }  
      }

    useEffect(() => {
        const deleteAccount = async() => {
            try {
                await auth.currentUser.delete()
                setDeleteUserAccount({...deleteUserAccount, deleting: false, deleted: true })
            } catch (error) {
                window.alert('Delete Account Error')
                setDeleteUserAccount({...deleteUserAccount, deleting: false })
            }
        }

        if (isReAuthenticated) {
            deleteAccount()
        }
    },[isReAuthenticated])

    useEffect(() => {
        if (createAccountDeletionError) {
            setBtnDisable(false)
        }

        return () => dispatch(createAccountDeletionReset())
    },[createAccountDeletionError])

  return (
    <div>
        <div className="dboard">
            <MdManageAccounts size={40} color='#010A58' />
            <p className='dboard__text'>Manage Account</p>
        </div>
        <AttributeContainer>
            <AttributeField>Email:</AttributeField>
            <p>{email || fbuser.email}</p>
        </AttributeContainer>
        {!fbuser ? <AttributeContainer>
                        <AttributeField>Created At:</AttributeField>
                        <p>{created_at.split('T')[0]}</p>
                    </AttributeContainer>: null }
        <AttributeContainer>
            <AttributeField>Email Verified:</AttributeField>
            <p>{email_verified ? 'true': fbuser?.emailVerified ? 'true': 'false' }</p>
        </AttributeContainer>
        <div className="detail__item">
                <div className="detail__block">
                    <div className="action__btns">
                        <div className="action__btn action__delete">
                            <div className="action__text">
                                    <h2>Delete your Account</h2>
                                    {isClientAccount ? (
                                    <p>This action will delete your Account</p>) :  (
                                    <p>This will delete your TapAService Account with all your Service Provider Profiles. Can not be undone</p>
                                )}
                            </div>
                            <Button borderRadius='7px' 
                                    border='none' 
                                    background='red' 
                                    color='white'
                                    {...getToggleProps({ onClick: () => setIsDeleteExpanded(prev => !prev),})}>
                                        Delete
                            </Button>
                        </div>
                        <div {...getCollapseProps()}>
                        {
                            !isClientAccount ? <DeleteNote>If you choose to continue this action will create a PENDING DELETE REQUEST for your TapAService Account that will be reviewed for any outstanding issues. If there aren't any issues your Account will be deleted with ALL your Profiles and you will be notified via email.</DeleteNote>: null 
                            }
                            <div className="tower__delete"  >    
                            <input
                                type='text'
                                value={text}
                                name='delete'
                                className="tower__input"
                                placeholder="type 'DELETE'"
                                onChange={(e) => setText(e.target.value)}
                                />
                            { text === 'DELETE' ? <Button borderRadius='7px'
                                                                background='red' onClick={handleDeleteSubmit} 
                                                                disabled={btnDisable}
                                                                color='white' 
                                                                border='none'>Confirm Delete
                                                            </Button> : null }
                            </div>
                             <div className="tower__progress">
                                { 
                                deleteUserAccount.deleting ? <Uploading text="deleting account.." color={'black'}/> :
                                creating ? <Uploading text="creating request.." color={'black'} /> : 
                                createAccountDeletionError ? <p className='error'>Error creating Account deletion request</p> :
                                created ? <p style={{ color: 'green' }}>Account Deletion Request created successfully</p> : null
                                }
                            </div>
      </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ManageAccount