import React from 'react'
import {FaTimesCircle} from 'react-icons/fa'
import { Container, Text } from './pay-success'

function PaymentCancelled() {
  

  return (
    <Container>
        <FaTimesCircle size={50} />
        <Text>Payment Cancelled</Text>
    </Container>
  )
}

export default PaymentCancelled