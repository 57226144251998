import React from 'react'
import styled from 'styled-components'

const BadgeContainer = styled.div`
    display: inline;
    background: hsl(220, 85%, 85%);;
    width: 12px;
    height: 12px;
    padding: 2px;
    border-radius: 50%;
    color: hsl(220, 100%, 20%);
    position: absolute;
    top: 0;
    right: -5px;
    font-size: 8px;
    text-align: center;
    font-weight: bold;
`

function Badge({ value }) {
  return (
    <BadgeContainer>{value}</BadgeContainer>
  )
}

export default Badge