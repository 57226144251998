import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import Review from "./review";
import { reviewsPaginationUpdate } from "./list.slice";
import ProductListingSkeleton from "../skeletons/product-list";
import { Filler } from "../skeletons/filler";

const ReviewsList = ({ companyEmail, type }) => {
    const { loading, lastEvaluatedKey, reviews } = useSelector(state => state.reviews)
    // const { loading, lastEvaluatedKey, profiles } = useSelector(state => state.profiles)
    const dispatch = useDispatch()
    const rowHeights = useRef({});
    const listRef = useRef({});

    const loadNextPage = () => {
        lastEvaluatedKey && dispatch(reviewsPaginationUpdate({ lastEvaluatedKey, companyEmail, type }))
    }
    
    const itemCount = lastEvaluatedKey ? reviews.length + 1 : reviews.length;
    const isItemLoaded = index => !lastEvaluatedKey || index < reviews.length;
    const isNextPageLoading = (loading && reviews.length) ? true : false
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

    function getRowHeight(index) {
        return rowHeights.current[index] + 8 || 82;
      }

    const Row = ({ data, index, style }) => {
        const rowRef = useRef({});

        useEffect(() => {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line
        }, [rowRef]);

        return (
            <div style={style}>
                <div ref={rowRef}>
                    { isItemLoaded(index) ? <Review data={data[index]} />: <Filler height={100} /> }
                </div>
            </div>
        )
    }

    function setRowHeight(index, size) {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
      }

    

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={1}
            >
                {({ onItemsRendered, ref }) => (
                    <AutoSizer>
                        {({ height, width }) => (     
                            <VariableSizeList
                                itemData={reviews}
                                onItemsRendered={onItemsRendered}
                                height={height}
                                itemCount={itemCount}
                                itemSize={getRowHeight}
                                width={width}
                                ref={listRef}
                               
                            >
                                {Row}
                        </VariableSizeList>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
    )
}

export default ReviewsList