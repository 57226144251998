import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {API, graphqlOperation} from 'aws-amplify';
import { useAuth0 } from '@auth0/auth0-react';

import { messagesByChannelID, getChatsListByReceiver } from '../graphql/queries';
import { sendMessageSuccess } from '../Components/chat/chat.slice';
import { updateMessage } from '../graphql/mutations';
import { onCreateMessage } from '../graphql/subscriptions';

export const useGetAndUpdateLastMessage = (channelID,items) => {
    const [dblastMessage, setDBLastMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [isErrorSet, setIsErrorSet] = useState(false)
    const { getAccessTokenSilently, user: { email } } = useAuth0()

    const fetchState = async() => {
      try {
        const authToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
        API
          .graphql(graphqlOperation(messagesByChannelID, {
            channelID,
            sortDirection: 'ASC'
          }, { authToken }))
          .then((response) => {
            const items = response?.data?.messagesByChannelID?.items;
            let clientEmail = channelID.split('_')[0]
            const messages = items.filter(item => (item.author === clientEmail && item.receiver === email) ||
             (item.author === email && item.receiver === clientEmail)) 
            if (messages.length) {
              setDBLastMessage(messages[messages.length - 1])
              setLoading(false)
            } else {
              setLoading(false)
            }
          })
          .catch(err => {
            setLoading(false)
            window.alert('Network Error. Failed to fetch messages. Try again later')
          })  
      } catch (error) {
        setLoading(false)
        window.alert('Network Error. Failed to fetch messages. Try again later')
      }
    }
  
    useEffect(() => {
      const load = async() => {
        setLoading(true)
        fetchState()
      }

      load()
     
    }, []);

    useEffect(() => {
      const refetch = async() => {
        fetchState()
      }

      refetch()
     
    }, [items]);

    useEffect(() => {
      const registerSubscription = async () => {
        try {
          const authToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          })
          const subscription = API
            .graphql(graphqlOperation(onCreateMessage, {}, authToken ))
            .subscribe({
              next: (event) => {
                const newMessage = event.value.data.onCreateMessage
                const clientEmail = channelID.split('_')[0]
                if (newMessage.channelID === channelID && (
                  (newMessage.author === email && newMessage.receiver === clientEmail) || 
                  (newMessage.author === clientEmail && newMessage.receiver === email)
                )) {
                  setDBLastMessage(newMessage);
                }
              },
              error: (err) => {
                if (isErrorSet) {
                  setIsErrorSet(true)
                  window.alert('Connection closed. You wont be able to receive incoming meesages. Reload Page and try again.')
                }
              }
            });
          
          return () => {
            subscription.unsubscribe();
          };
        } catch (error) {
          window.alert('Network Error. You wont be able to receive incoming pages. Reload page and try again.')
        }
      }
      registerSubscription()
      
      }, [isErrorSet]);  //dblastMessage as dependency
  
    return { dblastMessage, loading }
  }

export const useListAndCreateMessage = (chatInfo, setRefetchLastMessage) => {
    const [messages, setMessages] = useState({ items: [], err:'', fetched: false,loading: true });
    const messagesRef = useRef(messages.items)
    const [messageText, setMessageText] = useState('')
    const { getAccessTokenSilently, user: { email } } = useAuth0()
    const dispatch = useDispatch()
    const todayRef = useRef()
    const isBookingAuthorisedRef = useRef(false)
    const [isErrorSet, setIsErrorSet] = useState(false)
    let dblastMessage

    const { channelID, chatEmail } = chatInfo

    const dateFormating = (messages) => {
        const dates = {}
        const msgs = []
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = new Date().toLocaleString("en-US", options)
        for (const msg of messages) {  
          const date = new Date(msg.createdAt)
          const stringDate = date.toLocaleDateString("en-US", options)

          if (!dates[stringDate]) {
            if (stringDate === today) {
              msgs.push({ type: 'date', value: 'Today', createdAt: Math.random() }, msg)
              todayRef.current = true
            } else {
              msgs.push({ type: 'date', value: stringDate, createdAt: Math.random() }, msg)
            }
            dates[stringDate] = stringDate
          } else {
            msgs.push(msg)
          }
        }
        return msgs
    }

    const fetchMessages = async () => {
      isBookingAuthorisedRef.current = false
      try {
        const authToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
        API
        .graphql(graphqlOperation(messagesByChannelID, {
          channelID: channelID,
          sortDirection: 'ASC'
        }, { authToken }))
        .then(async(response) => {
          const items = response?.data?.messagesByChannelID?.items;
          if (items && items.length) {
            const chatMessages = items.filter(item => {
              if (item.body.includes('bac_') && item.receiver === chatEmail && item.author === email) {
                isBookingAuthorisedRef.current = true
              }
              return ((item.author === email && item.receiver === chatEmail) || (item.receiver === email && item.author === chatEmail))
            })
            let dateFormattedMessages = dateFormating(chatMessages)
            setMessages({...messages, items: dateFormattedMessages, fetched: true, loading: false });
            messagesRef.current = dateFormattedMessages
            dblastMessage =  items[items.length - 1]
            if (dblastMessage && dblastMessage.author !== email && !dblastMessage.read) {
                let input = { id:dblastMessage.id, read: true};
                try {
                  await API.graphql(graphqlOperation(updateMessage, { input }, { authToken }))
              } catch (error) {
                  console.warn(error);
              }
            }
          } else {
            setMessages({...messages, fetched: true, loading: false });
          }
        })
        .catch(err => {
          setMessages({...messages, fetched: false, loading:false, err: err });
        })
      } catch (error) {
        setMessages({...messages, fetched: false, loading:false, err: error });
      }
    }

    const refetch = async() => {
      setMessages({ items: [], err:'', fetched: false,loading: true })
      await fetchMessages()
    }

    useEffect(() => {
      const getChatMessages = async() => {
        fetchMessages()
      }
      getChatMessages()
        
      }, [channelID]);
    
      useEffect(() => {
        const registerCreateSubscription = async() => {
          try {
            const authToken = await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            const subscription = API
          .graphql(graphqlOperation(onCreateMessage,{},authToken ))
          .subscribe({
            next: (event) => {
              const { receiver, author, channelID: CID, body, createdAt } = event.value.data.onCreateMessage
              if (receiver === email && channelID === CID && !messagesRef.current.find(e => e.body === body && e.createdAt === createdAt) ) {
                const currentMessages = messagesRef.current
                if (todayRef.current) {
                  setMessages({...messages,items:[...currentMessages,event.value.data.onCreateMessage], loading: false});
                  messagesRef.current = [...currentMessages,event.value.data.onCreateMessage]
                } else {
                  setMessages({...messages,items:[...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, event.value.data.onCreateMessage], loading:false });
                  messagesRef.current = [...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, event.value.data.onCreateMessage]
                  todayRef.current = true
                }    
              } else if (author === email && channelID === CID) {
                dispatch(sendMessageSuccess({ message: event.value.data.onCreateMessage }))
              }
            },
            error: (err) => {
              if (!isErrorSet) {
                setIsErrorSet(true)
                window.alert('Connection closed. You wont be able to receive incoming meesages. Reload Page and try again.')
              }
            }
          });
        
          return () => {
            subscription.unsubscribe();
          };
          } catch (error) {
            window.alert('Network Error. You wont be able to receive incoming pages. Reload page and try again.')
          }
        }

      registerCreateSubscription()  
      }, [isErrorSet]);

      return { messages, messagesRef, messageText, setMessageText, setMessages, todayRef, refetch, isBookingAuthorised: isBookingAuthorisedRef.current }
}

export const useGetChatsList = () => {
    const [chats, setChats] = useState({ items:[], loading:true, err:'', fetched: false })
    const [isErrorSet, setIsErrorSet] = useState(false)
    const { user: { email }, getAccessTokenSilently } = useAuth0()
    const chatsRef = useRef(chats.items)
    const messagesRef = useRef([])

    const refetch = async(nextToken, authToken) => {
      try {
        const response = await API
                                .graphql(graphqlOperation(getChatsListByReceiver, {
                                  receiver: email,
                                  sortDirection: 'DESC',
                                  nextToken
                                },{ authToken }))
        const token = response?.data?.getChatsListByReceiver?.nextToken
        const items = response?.data?.getChatsListByReceiver?.items;
        return { token, items } 
      } catch (error) {
        console.log(error);
      }
    }

    const getChats = async() => {
      try {
        const authToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
        API
      .graphql(graphqlOperation(getChatsListByReceiver, {
        receiver: email,
        sortDirection: 'DESC',
      },{ authToken }))
      .then(async(response) => {
        const items = response?.data?.getChatsListByReceiver?.items;
        messagesRef.current = [...items]
        let nextToken = response?.data?.getChatsListByReceiver?.nextToken
        while (nextToken) {
          const results = await refetch(nextToken, authToken)
          messagesRef.current = [...messagesRef.current, ...results.items]
          nextToken = results.token
        }
        if (messagesRef.current.length) {
          let msgSenders = []
          for (const message of messagesRef.current) {
            !msgSenders.includes(message.author) && message.channelID.includes('_vm') &&  
            msgSenders.push(message.author)
            }
          setChats({...chats, items: [...msgSenders], loading: false, fetched: true });
          chatsRef.current = msgSenders
        } else {
          setChats({...chats, loading: false, fetched: true })
        }
      })
      .catch(err => {
        setChats({...chats, loading: false, fetched: false, err})
      })
      } catch (err) {
        setChats({...chats, loading: false, fetched: false, err})
      }
    }

    const reload = async() => {
      setChats({ items:[], loading:true, err:'', fetched: false })
      await getChats()
    }

    useEffect(() => {
      const fetch = async() => {
        await getChats()
      }
      fetch()
    }, []);

    useEffect(() => {
      const registerCreateSubscription = async () => {
        try {
          const authToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          })
          const subscription = API
            .graphql(graphqlOperation(onCreateMessage, {}, authToken ))
            .subscribe({
              next: (event) => {
                const { author, receiver } = event.value.data.onCreateMessage
                const currentChats = chatsRef.current
                if (author !== email && receiver === email) {
                  if (!currentChats.includes(author)) {
                    setChats({...chats, items: [author,...currentChats], loading: false})
                    chatsRef.current = [author,...currentChats]
                  } else if (currentChats.includes(author)) {
                    currentChats.splice(currentChats.indexOf(author),1)
                    setChats({...chats, items: [author,...currentChats], loading: false})
                    chatsRef.current = [author,...currentChats]
                  }
                } else if (author === email) {
                    currentChats.splice(currentChats.indexOf(receiver),1)
                    setChats({...chats, items: [receiver,...currentChats], loading: false})
                    chatsRef.current = [receiver,...currentChats]
                }
              },
              error: (err) => {
                if (!isErrorSet) {
                  setIsErrorSet(true)
                  window.alert('Connection closed. You wont be able to receive incoming meesages. Reload Page and try again.')
                }
              }
            });
          
          return () => {
            subscription.unsubscribe();
          };
        } catch (error) {
          window.alert('Network Error. You wont be able to receive incoming pages. Reload page and try again.')
        }
      }
      registerCreateSubscription()
    }, [isErrorSet]); //dblastMessage as dependency

    return { chats, chatsRef, reload }
}


