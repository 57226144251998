import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import styled, { css } from 'styled-components'
import GoogleMapReact from 'google-map-react';
import { BsStarFill } from 'react-icons/bs'

import ServiceItem from '../../../Components/serviceItem/serviceItem'
import { StaticSlider } from '../../../Components/slider/slider'
import ImageViewer from '../../../Components/imageViewer/imageViewer'
import ProfileDetailSkeleton from '../../../Components/skeletons/product-detail'
import { fetchProfileDetail, fetchServiceDetailReset } from '../../../Components/service/service.detail.slice'
import { fetchRoadsideProfileDetail, fetchProfileDetailReset } from '../../../Components/service/profile.detail.slice'
import { fetchServicesSubscription } from '../../../Components/subscriptions/services.slice'
import Footer from '../../../Components/footer/footer'

const Button = styled.button`
    width: 200px;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: ${props => props.background || '#7d7de2' };
    color: white;
    cursor: pointer;
    ${(props) => props.disabled && css`
        cursor: none;
        pointer-events: none;
        opacity: 0.5;
        `
      }
`

const Service = styled.div`
    padding: 20px;
    background: #f9f9f9;
    border-radius: 5px;
`

const Subheading = styled.p`
    font-weight: 500;
`

const ActionsTitle = styled.p`
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
`

const CallToAction = styled.div`
    padding-bottom: 5px;
    @media (min-width: 500px) {
        display: flex;
    }
`

const Applinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 25px 10px;
`

export const Dot = styled.div`
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 10px;
`

function ServiceDetail() {
    const { subscription, subscriptionError} = useSelector(state => state.servicesSubscription)
    const { loading, profile, profileError } = useSelector( state => state.profileDetail)
    const { loading: rloading, profile: rprofile, profileError: rprofileError } = useSelector( state => state.roadsideProfileDetail)
    const [viewState, setViewState] = useState(null);
    const [isTierLimitReached, setIsTierLimitReached] = useState(true)
    const { isAuthenticated } = useAuth0()
    const history = useHistory()
    let { email } = useParams()
    const { search } = useLocation()
    const dispatch = useDispatch()
    
    const { type, cid } = queryString.parse(search)

    useEffect(() => {
        if (type === 'car-service' ) {
            // dispatch(fetchServicesSubscription('Vehicle Maintenance'))
            dispatch(fetchProfileDetail(decodeURIComponent(email)))
        } else {
            // dispatch(fetchServicesSubscription('Roadside Assist'))
            dispatch(fetchRoadsideProfileDetail(cid,decodeURIComponent(email)))
        } 
        
        return () => {
            dispatch(fetchProfileDetailReset())
            dispatch(fetchServiceDetailReset())
            setViewState(null)
        }
    },[])

    useEffect(()=>{
        const addMap = async () => {
            if (Object.keys(profile).length || Object.keys(rprofile).length) {
                if (Object.keys(profile).length) {
                    setViewState({ latitude: profile.address[0].coordinates[1],
                                    longitude: profile.address[0].coordinates[0],
                                    zoom: 11,
                                    pitch: 45 })
                } else if (Object.keys(rprofile).length) {
                    setViewState({ latitude: rprofile.address[0].coordinates[1],
                        longitude: rprofile.address[0].coordinates[0],
                        zoom: 11,
                        pitch: 45 })
                }
            }
        }
        addMap()

    },[profile,rprofile])

    // useEffect(() => {
    //     if (Object.keys(profile).length && subscription) {
    //         let { subscriptionTier, bookingsCount, currentSubscriptionTier } = profile
    //         let key = currentSubscriptionTier ? currentSubscriptionTier.toLowerCase(): subscriptionTier.toLowerCase()
    //         if (bookingsCount < subscription.plans[key].max) {
    //             setIsTierLimitReached(false)
    //         }
    //     }
    // },[profile, subscription])

    const openReviews = () => {
        const reviewsCount = profile?.reviewsCount ? profile.reviewsCount : rprofile?.reviewsCount
        const rating = profile?.rating ? profile.rating : rprofile?.rating
        history.push(`/service/${encodeURIComponent(email)}/reviews`, { reviewsCount, rating, type: type === 'car-service' ? 'vehicleMaintenance': 'roadsideAssist' })
    }

    const distanceToMouse = (pt, mp) => {
        if (pt && mp) {
          // return distance between the marker and mouse pointer
          return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
          );
        }
      };

  return (
    <>
        {
            ( loading || rloading ) ? <ProfileDetailSkeleton /> :
            ( Object.keys(profile).length || Object.keys(rprofile).length ) ? (
        <>
        <div className='detail'>
            <div className='detail__logo-container'>
                <img className='detail__logo' src={`${process.env.REACT_APP_S3_BUCKET_URL}/${profile.logo || rprofile.logo}`} alt="logo"  />
            </div>
            <div className='detail__item'>
                <p className='detail__subheading'>Company Name</p>
                <p>{profile.companyName || rprofile.companyName}</p>
            </div>
            <div className='detail__item'>
                <p className='detail__subheading'>Statement</p>
                <p>{profile.statement || rprofile.statement}</p>
            </div>
            {/* <div className='detail__item'>
                <p className='detail__subheading'>Email</p>
                <p>{decodeURIComponent(profile.email) || decodeURIComponent(rprofile.email)}</p>
            </div>
            <div className='detail__item'>
                <p className='detail__subheading'>Phone</p>
                {
                    (profile.phoneNo || rprofile.phoneNo).map((num,idx) => <span key={idx}>{num} </span>)
                }
            </div> */}
            { (profile?.reviewsCount || rprofile?.reviewsCount) ? (
            <div className='detail__item'>
                <p className='detail__subheading'>Rating</p>
                <p><BsStarFill size={12} color='#FFE662' /> {profile.rating || rprofile.rating}</p>
                <Button onClick={openReviews}>View Reviews</Button>
            </div>
            ): null }
            {
                type === 'roadside-assist' ? (
                <>
                    <div className='detail__item'>
                        <p className='detail__subheading'>Towing Rates (ZAR/km)</p>
                    {/* <p>{rprofile.rate}</p> */}
                    <p>Flatbed: {rprofile.rate.flatBed}</p>
                    <p>Wheel Lift: {rprofile.rate.wheelLift}</p>
                    <p>Hook & Chain: {rprofile.rate.hookChain}</p>
                    </div>
                    <div className='detail__item'>
                        <p className='detail__subheading'>Surge (%)</p>
                    <p>{rprofile.deviation}</p>
                    </div>
                </>
                ) : null
            }
            { type === 'car-service' ? (
                <>
                <div className='detail__item'>
                    <p className='detail__subheading'>Service Rate/hr (ZAR)</p>
                    <p>{profile.price}</p>
                </div>
                <div className='detail__item'>
                    <p className='detail__subheading'>Service Tiers</p>
                    {
                        profile.serviceTiers && ['minor','major'].map((tier,idx)=>{
                            return (
                                <div key={idx}>
                                    <p>{tier.charAt(0).toUpperCase() + tier.slice(1)}:</p>
                                    <div className='detail__option-container'>
                                        {profile.serviceTiers[tier].map((item,idx) => <ServiceItem key={idx} text={item} />)}
                                    </div>
                                </div>
                                
                            )
                    })}
                </div>
                </>
                ) : type === 'roadside-assist' ? (
                     <div className='detail__item'>
                     <p className='detail__subheading'>Services</p>
                     {
                         rprofile.services && rprofile.services.map((service,idx)=> <Service key={idx}>{service}</Service>)
                     }
                 </div> 
                ) : null }
            <div className='detail__item'>
                <p className='detail__subheading'>Images</p>
                <div className='detail__slider'>
                    <StaticSlider data={profile.images || rprofile.images} />
                </div>
                <div className="detail__imgviewer">
                    <ImageViewer images={profile.images || rprofile.images} />
                </div>
            </div>
            <div className='detail__item'>
                <p className='detail__subheading'>Promo Video</p>
                <div className="detail__video-container">
                            <video className='detail__video'  controls  >
                                <source type="video/mp4" src={`${process.env.REACT_APP_S3_BUCKET_URL}/${profile.video || rprofile.video}`}/>
                                <source type="video/webm" src={`${process.env.REACT_APP_S3_BUCKET_URL}/${profile.video || rprofile.video}`}/>
                                <source type="video/ogg" src={`${process.env.REACT_APP_S3_BUCKET_URL}/${profile.video || rprofile.video}`}/>
                                Video tag not supported
                            </video>
                </div>
            </div> 
            <div className='detail__item'>
                <p className='detail__subheading'>Address(es)</p>
                <div className='detail__addresses detail__block'>
                    {(profile.address || rprofile.address).map((addr,idx)=> {
                        return (
                            <p key={idx} className='detail__address'>{addr.address}</p>
                        )
                    })}
                </div> 
                <div className="detail__block" >
                <div style={{ height: '40vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAG2L_SwgYlgPy4OnzCoADp0Z8v-OI9hvk", language: 'en-us' }}
                        center={{ lat: viewState?.latitude, lng: viewState?.longitude }}
                        defaultZoom={11}
                        distanceToMouse={distanceToMouse}
                    >
                       {
                        profile ? profile.address?.map(({ address, coordinates},idx) => {
                            return (
                                <Dot   key={idx}
                                                text={address}
                                        
                                                lat={coordinates[1]} 
                                                lng={coordinates[0]} 
                                />
                            )
                        }) : rprofile ? rprofile.address?.map(({ address, coordinates},idx) => {
                            return (
                                <Dot   key={idx}
                                                text={address}
                                                
                                                lat={coordinates[1]} 
                                                lng={coordinates[0]} 
                                />
                            )
                        }) : null
                       }
                    </GoogleMapReact>
    </div>
                </div> 
                </div>
                <div style={{ backgroundColor: 'rgba(118, 195, 212, 0.23)', paddingTop: 15, marginBottom: 60 }}>
                    <div >
                    {
                        type === 'car-service' ? <ActionsTitle >Make a Booking</ActionsTitle>:
                        <ActionsTitle>Make a Roadside Assistance Request</ActionsTitle>
                    }
                    </div>
                <CallToAction>
                    {/* <Applinks>   
                        {
                            type === 'car-service' ? <Subheading>Create Booking on App</Subheading> :
                            <Subheading>Download App to have access to realtime roadside assistance</Subheading>
                        }
                        <div className="app__links">
                            <div className='app__android' />
                            <div className='app__ios' />
                        </div>
                    </Applinks>   */}
                    { (!isAuthenticated && type === 'car-service') ? (
                    <div className="detail__item">
                        <Subheading>Create Booking on Browser</Subheading>
                        <Button onClick={() => history.push('/booking/create/info',{ address: profile.address, email: decodeURIComponent(email), companyName: profile.companyName })} 
                                background='#5282BD'
                                >
                                    Create Booking on Browser
                        </Button>
                    </div>
                    ) : null }
                </CallToAction>
            </div>
        </div>
        <Footer />
        </>
            )
        : <p>{profileError || rprofileError}</p>}
    </>
  )
}

export default ServiceDetail