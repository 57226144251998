import { createSlice } from "@reduxjs/toolkit"

import { geocode } from "../../utils/map"
import customAxios from '../../redux/axios/axios'

const initialState = {
  creating : false,
  created: false,
  createProfileError: ''
}

const createService = createSlice({
  name: 'createService',
  initialState,
  reducers: {
    createProfileRequest(state) {
      state.creating = true
    },
    createProfileSuccess(state, action) {
        state.creating = false
        state.created = true
    },
    createProfileError(state, action) {
      state.creating = false
      state.createProfileError = action.payload
    },
    createProfileReset(state) {
      state.creating = false
      state.createProfileError = ''
      state.created = null
    },
  }
})

export const { createProfileRequest, createProfileSuccess, createProfileError, createProfileReset } = createService.actions

export const createProfile = (data,type,id,token, countryCode) => async (dispatch) => {
  const geocodedLocations = await geocode(data.address,countryCode)
  try {
      dispatch(createProfileRequest())
      await customAxios.post('/services',{...data,geocodedLocations },{ params: { type, id },
                                                                        headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(createProfileSuccess())
  } catch (error) {
      dispatch(createProfileError(error))
  }
}

export default createService.reducer
