import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  updating : false,
  towerUpdate: false,
  towerUpdateError: ''
}

const towerUpdate = createSlice({
  name: 'towerUpdate',
  initialState,
  reducers: {
    updateTowerRequest(state) {
      state.updating = true
    },
    updateTowerSuccess(state, action) {
        state.updating = false
        state.towerUpdate = true
    },
    updateTowerError(state, action) {
      state.updating = false
      state.towerUpdateError = action.payload
    },
    updateTowerReset(state) {
      state.updating = false
      state.towerUpdateError = ''
      state.towerUpdate = false
    },
  }
})

export const { updateTowerRequest, updateTowerSuccess, updateTowerError, updateTowerReset } = towerUpdate.actions

export const updateTower = (postData,id,token) => async (dispatch) => {
  dispatch(updateTowerRequest())
  try {
      await customAxios.put('/towers',postData,{ params: { id },
                                                  headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(updateTowerSuccess(true))
  } catch (error) {
      dispatch(updateTowerError(error.message))
  }      
}

export default towerUpdate.reducer
