import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const deleteReviewState = {
  deleting : false,
  deleted: false,
  deleteError: ''
}

const createReviewState = {
  creating : false,
  created: false,
  createReviewError: ''
}

const reviewDetailState = {
  loading : false,
  review: {},
  reviewError: ''
}

const review = createSlice({
  name: 'review',
  initialState: reviewDetailState,
  reducers: {
    fetchedReviewRequest(state) {
      state.loading = true
    },
    fetchedReviewSuccess(state, action) {
        state.loading = false
        state.review = action.payload
    },
    fetchedReviewError(state, action) {
      state.loading = false
      state.reviewError = action.payload
    },
  }
})

const { fetchedReviewError, fetchedReviewRequest, fetchedReviewSuccess } = review.actions

export const fetchReviewDetail = (email, companyEmail) => async (dispatch) => {
  dispatch(fetchedReviewRequest())
  try {
      const {data} = await customAxios.get(`/review/${encodeURIComponent(email)}`,{ params: { companyEmail }})
      if (data.Items.length) {
          dispatch(fetchedReviewSuccess(data.Items[0]))
      } else {
          dispatch(fetchedReviewSuccess({}))
      }   
  } catch (error) {
      dispatch(fetchedReviewError(error))
  }      
}

const deleteReviewSlice = createSlice({
  name: 'deleteReview',
  initialState: deleteReviewState,
  reducers: {
    deleteReviewRequest(state) {
      state.deleting = true
    },
    deleteReviewSuccess(state) {
        state.deleting = false
        state.deleted = true
    },
    deleteReviewError(state, action) {
      state.deleting = false
      state.deleteError = action.payload
    },
    deleteReviewReset(state) {
      state.deleting = false
      state.deleteError = ''
      state.deleted = false
    },
  }
})

export const { deleteReviewError, deleteReviewRequest, deleteReviewSuccess, deleteReviewReset } = deleteReviewSlice.actions

export const deleteReview = (email, companyEmail) => async (dispatch) => {
  dispatch(deleteReviewRequest())
  try {
      await customAxios.delete(`/review/${encodeURIComponent(email)}`,{ params: { companyEmail }})
      dispatch(deleteReviewSuccess())  
  } catch (error) {
      dispatch(deleteReviewError(error))
  }      
}

const createReviewSlice = createSlice({
  name: 'createReview',
  initialState: createReviewState,
  reducers: {
    createReviewRequest(state) {
      state.creating = true
    },
    createdReviewSuccess(state) {
        state.creating = false
        state.created = true
    },
    createdReviewError(state, action) {
      state.creating = false
      state.createReviewError = action.payload
    },
    createdReviewReset(state) {
      state.creating = false
      state.createReviewError = ''
      state.created = false
    },
  }
})

export const { createReviewRequest, createdReviewError, createdReviewSuccess, createdReviewReset } = createReviewSlice.actions

export const createReview = (data) => async (dispatch) => {
  dispatch(createReviewRequest())
  try {
      await customAxios.post('/review',data);
      dispatch(createdReviewSuccess())
  } catch (error) {
      dispatch(createdReviewError(error.message))
  }
}

export const reviewDetailReducer = review.reducer
export const createReviewReducer = createReviewSlice.reducer
export const deleteReviewReducer = deleteReviewSlice.reducer

