import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { api } from '../Components/api/api.slice'

export default configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      ...rootReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
    serializableCheck: { warnAfter: 128 },
    }).concat(api.middleware)
  })