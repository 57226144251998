import React from 'react';
import StarRatings from 'react-star-ratings';

const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

const Review = ({ data }) => {

    function timeSince(date) {
        const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
        const interval = intervals.find(i => i.seconds < seconds);
        const count = Math.floor(seconds / interval.seconds);
        return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
        }

    return (
        <div className="review">
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <p className="review__author">
                    {data?.consumerName}
                </p>
                <p className="review__author">{timeSince(new Date(data?.datetime))}</p>
            </div>
            <p className="review__text">
                {data?.review}
            </p>
            <StarRatings
                rating={data?.rating}
                starRatedColor="#ffa534"
                starDimension="12px"
                starSpacing="3px"
                numberOfStars={5}
                name='rating'
            />
        </div>
    )
}

export default React.memo(Review)