import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ProfileDetailSkeleton() {
  return (
    <div className='detail'>
        <div className='detail__logo-container'>
            <Skeleton className='detail__logo'  />
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Company Name</p>
            <Skeleton width={250} />
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Statement</p>
            <Skeleton width={250} />
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Rating</p>
            <Skeleton width={20} />
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Service Tiers</p>
            {
                ['routine','intermediate','inDepth'].map((tier,idx)=>{
                    return (
                        <div key={idx}>
                            <p>{tier.charAt(0).toUpperCase() + tier.slice(1)}:</p>
                            <div className='detail__option-container'>
                                {Array.apply(null, Array(10)).map((item,idx) => <Skeleton key={idx} width={50} />)}
                            </div>
                        </div>
                        
                    )
            })}
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Images</p>
                <Skeleton height={350} />
        </div>
        <div className='detail__item'>
            <p className='detail__subheading'>Promo Video</p>
            <div className="detail__video-container">
                <Skeleton className='detail__video' height={400} />
            </div>
        </div>   
    </div>
  )
}

export default ProfileDetailSkeleton