import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ReviewsListSkeleton() {
  return (
    <div className='reviews'>
        <div className="reviews__header">
            <h2>REVIEWS</h2>
            <Skeleton height={30} width={100} />
            <Skeleton height={30} width={100} />
            <Skeleton height={30} width={100} />
        </div>
        <div className="reviews__list-container">
            {Array.apply(null, Array(20)).map((val,idx) => <Skeleton height={100} />)}
        </div>
    </div>
  )
}

export default ReviewsListSkeleton