import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
    loading : false,
    profiles: [],
    profilesError: '',
    lastEvaluatedKey: null,
    loadingMore: false
}

const roadsideProfiles = createSlice({
  name: 'roadsideProfiles',
  initialState,
  reducers: {
    fetchRoadsideProfilesRequest(state) {
      state.loading = true
    },
    fetchMoreRoadsideProfilesRequest(state) {
      state.loadingMore = true
    },
    fetchedRoadsideProfilesSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.profiles = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMoreRoadsideProfilesSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.profiles = [...state.profiles,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
      state.loadingMore = false
    },
    fetchedRoadsideProfilesError(state, action) {
        state.loading = false
        state.loadingMore = false
        state.profilesError = action.payload
      },
  }
})

const { fetchRoadsideProfilesRequest, fetchedRoadsideProfilesSuccess,
                fetchedMoreRoadsideProfilesSuccess, fetchedRoadsideProfilesError,
                fetchMoreRoadsideProfilesRequest } = roadsideProfiles.actions

export const fetchRoadsideProfiles = (postData) => async (dispatch) => {
    dispatch(fetchRoadsideProfilesRequest())
    try {
        const { data } = await customAxios.post('/roadside-assist/profiles',{...postData});
        const { Items, LastEvaluatedKey} = data
        dispatch(fetchedRoadsideProfilesSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedRoadsideProfilesError(error))
    }      
}

export const roadsidePaginationUpdate = (postData) => async (dispatch) => {
    try {
        dispatch(fetchMoreRoadsideProfilesRequest())
        const { data } = await customAxios.post('/roadside-assist/profiles',{...postData});
        const { Items, LastEvaluatedKey } = data
        dispatch(fetchedMoreRoadsideProfilesSuccess({ Items, LastEvaluatedKey}))
    } catch (error) {
        dispatch(fetchedRoadsideProfilesError(error))
    }
}

export default roadsideProfiles.reducer