import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StepperStep, StepperSteps } from "./stepper-step";

const Stepper = ({ steps, currentStep }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'SET_STEPS', payload: steps })
    },[])
    
    return (
        <div className="stepper-container">
            <div className="stepper-header">
                { steps.length ? steps.map((step, index) => (
                        <div 
                            key={index}
                            className={`stepper-header-item ${index === currentStep ? 'completed' : ''}`}
                        >
                            <div className="step-counter">{index + 1}</div>
                            <div className="step-name">{step.name}</div>
                        </div>
                )) : null }
            </div>
        </div>)
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;