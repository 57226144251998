import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

const containerStyle = {
  paddingLeft: '50px',
  textAlign: 'left'
}

const headingStyle = {
  marginTop: '35px',
  marginBottom: '10px',
  color: 'green',
  fontSize: '14px',
  fontWeight: 'bold'
}

const textStyle = {
  fontSize: '12px',
  lineHeight: '30px'
}

const CTA = {
  padding: '10px',
  fontSize: '12px',
  color: 'white',
  background: 'green',
  borderRadius: '8px',
  border: 'none',
  marginTop: '20px',
  cursor: 'pointer'
}

function CreateProfileSuccess({ type }) {
    const {  user:{ email } } = useAuth0();
  const history = useHistory()
    
  return (
    <div style={containerStyle} >
        <h2 style={headingStyle}>Profile created Successfully!</h2>
        <p style={textStyle}>You have successfully created your Service Provider Profile. It is still inactive at this point. <br/>
        To activate your profile you need to log in and upload your documents(.pdf).<br/>
        You need to upload the following: <br/>
          <div style={{ paddingLeft: '50px', lineHeight: 1.5 }}>
            <li>Company Registration Document</li>
            <li>Director ID</li>
            <li>Proof of Company Address</li>
            <li>Proof of Banking Details</li>
            { type === 'vehicle_maintenance' ? <>
              <li>Prof of Accreditation with Relevant Automotive Body (e.g RMI, MIWA)</li>
              <li>Certified Mechanic/Technician Licences</li>
              <li>Proof of Public Liability Insurance</li>
              <li>Certified Mechanic/Technician Licences</li>
              <li>Vehicle Service License (if applicable)</li>
              <li>Health and Safety Compliance Certificate</li>
            </>: type ==='roadside_assistance' ? <>
              <li>Proof of registration with SATRA (or any relevant Towing Body)</li>
              <li>All Driver Licences</li>
              <li>Proof of 3rd party Liability Cover</li>
              <li>Vehicle Registration Papers</li>
              <li>Vehicle Roadworthiness Certificates</li>
              <li>Proof of Comprehensive Insurance</li>
              <li>Letter of Good Standing with Compensation Fund</li>
            </>: null }
          </div> 
        Our team will verify your documents with relevant authorities.<br/>
        Once we have verified your documents we will send you a profile activation confirmation email. 
        
        </p>
        <button style={CTA} onClick={() => history.push(`/dashboard/${encodeURIComponent(email)}`)}>
          Go to Dashboard
        </button>
    </div>
  )
}

export default CreateProfileSuccess