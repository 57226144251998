
export const textValidator = (name) => {
  return !(/^[a-zA-Z\s]*$/.test(name))
}

export const emailValidator = (email) => {
  const regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !regexp.test(email)
}

export const yearValidator = (number) => {
  const regexp = new RegExp('^[0-9]+$');
  const currDate = new Date()
  return !(regexp.test(number) && number.length === 4 && number > 1990 && number <= currDate.getFullYear())
}

export const numberValidator = (number) => {
  const regexp = new RegExp('^[0-9]+$');
  return !(regexp.test(number) && number > 0)
}

export const lengthValidator = (string, limit) => {
  return !(string.length <= limit)
}

export const passwordTextValidator = (password) => {
  const regexp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{0,}$/
  return !(regexp.test(password))
}

export const passwordLengthValidator = (password) => {
  return !(password.length >= 8)
}

