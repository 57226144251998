import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  profile: {},
  profileError: ''
}

const profileDetail = createSlice({
  name: 'profileDetail',
  initialState,
  reducers: {
    fetchProfileDetailRequest(state) {
      state.loading = true
    },
    fetchProfileDetailSuccess(state, action) {
        state.loading = false
        state.profile = action.payload
    },
    fetchProfileDetailError(state, action) {
      state.loading = false
      state.profileError = action.payload
    },
    fetchProfileDetailReset(state) {
      state.loading = false
      state.profileError = ''
      state.profile = {}
    },
  }
})

export const { fetchProfileDetailRequest, fetchProfileDetailSuccess, fetchProfileDetailError, fetchProfileDetailReset } = profileDetail.actions

export const fetchRoadsideProfileDetail = (companyID,companyEmail) => async (dispatch) => {
  dispatch(fetchProfileDetailRequest())
  try {
      const { data } = await customAxios.get(`/roadside-assist`, { params: { companyEmail, companyID }})
      dispatch(fetchProfileDetailSuccess(data.Items[0]))
  } catch (error) {
      dispatch(fetchProfileDetailError(error))
  }      
}

export default profileDetail.reducer
