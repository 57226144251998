import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  requesting : false,
  requested: false,
  requestLinkError: ''
}

const emailLink = createSlice({
  name: 'emailLink',
  initialState,
  reducers: {
    requestLinkRequest(state) {
      state.requesting = true
    },
    requestLinkSuccess(state, action) {
        state.requesting = false
        state.requested = true
    },
    requestLinkError(state, action) {
      state.requesting = false
      state.requestLinkError = action.payload
    },
    requestLinkReset(state) {
      state.requesting = false
      state.requestLinkError = ''
      state.requested = false
    },
  }
})

export const { requestLinkRequest, requestLinkSuccess, requestLinkError, requestLinkReset } = emailLink.actions

export const requestLink = (user_id) => async (dispatch) => {
  try {
      dispatch(requestLinkRequest())
      await customAxios.post('/email-verification',{ user_id });
      dispatch(requestLinkSuccess())
  } catch (error) {
      dispatch(requestLinkError(error))
  }
}

export default emailLink.reducer
