import Compressor from 'compressorjs';

export const fileCompressor = async(file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.8,
            success: (result) => {
                resolve(new File([result], file.name, {type: result.type}))
            },
            error: (error) => reject(error)
        })
    });
}