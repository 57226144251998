import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  location: {},
  locationError: ''
}

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    loadingLocation(state) {
      state.loading = true
  },
    locationSuccess(state, action) {
        state.loading = false
        state.location = action.payload
    },
    locationError(state) {
      state.loading = false
      state.locationError = 'Error'
    },
  }
})

export const { locationError, locationSuccess, loadingLocation } = location.actions


export default location.reducer
