import React,{ useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API, graphqlOperation } from 'aws-amplify';
import { useAuth0 } from '@auth0/auth0-react';
import { BiSend } from 'react-icons/bi'
import { MdChat } from 'react-icons/md'
import { BiMessageSquareDots } from 'react-icons/bi'
import { MdRefresh } from "react-icons/md";
import { AiOutlineReload } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import short from 'short-uuid'

import { createMessage } from '../../graphql/mutations'
import Message from '../message/message';
import Profile from '../profile/profile';
import { sendMessageError, sendMessageRequest, sendMessageReset } from './chat.slice';
import { fetchFCMPushToken } from '../dashboard/redux/fcm.push.slice';
import { useListAndCreateMessage, useGetChatsList } from '../../utils/graphql-hooks';
import { OnlineStatus } from '../offline/Context';
import customAxios from '../../redux/axios/axios'
import { Button, FormButton } from '../button/Button';
import { InteractiveModal } from '../modal/Modal';

export const Note = styled.p`
  text-align: center;
`

export const Error = styled(Note)`
  color: red;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Alert = styled.p`
      background: rgb(118, 195, 212);
    padding: 5px;
    text-align: center;
    border-radius: 16px 16px 0 0;
`

export function Chat({ chatInfo, height }) { 
    const { token } = useSelector(state => state.fcmPushToken)  
    const { messages, messagesRef, messageText, setMessageText, setMessages, todayRef, refetch, isBookingAuthorised } = useListAndCreateMessage(chatInfo)
    const { user: { name, email, nickname }, getAccessTokenSilently } = useAuth0();
    const [authorisationCode, setAuthorisationCode] = useState({ creating: false, created: false, error: '' })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const online = useContext(OnlineStatus)
    const authorisationCodeRef = useRef(null)
    const messagesEndRef = useRef(null)
    const dispatch = useDispatch()
    const translator = short()

    useEffect(()=>{
      let isCancelled = false
      if (!isCancelled) {
        dispatch(fetchFCMPushToken(chatInfo.channelID.split('_')[0]))
      }

      return () => {
        isCancelled = true
        dispatch(sendMessageReset())
      }  
    },[])

    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    },[messages])

    useEffect(() => {
      if (authorisationCode.created) {
        handleMessageSubmit(chatInfo.channelID, email, authorisationCodeRef.current, true)
      }
    },[authorisationCode])

    const getAccessToken = async() => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
        return accessToken
      } catch (error) {
        console.log(error);
      }
    }

    const handleOnChange = (e) => {
        const {value} = e.target
        setMessageText(value)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!online) {
          return alert('Can not send message without internet connection')
        }
        handleMessageSubmit(chatInfo.channelID, email,messageText)
    }

    const sendMessagePushNotification = async() => {
     try {
      const authToken = await getAccessToken()
      const message = {
        "token": token,
        "notification":{
          "title": "You've got mail 📬",
          "body": `New message from ${name}`,
          "image": "https://tapaservice.s3.amazonaws.com/assets/notif-icon.png"
        },
        "data": {
          "screen": "Chats",
          "chatEmail": email, 
        }
      }
      await customAxios.post('/send-push-notification', { message },{
        headers: { Authorization: `Bearer ${authToken}`}
      })
     } catch (error) {
      console.log(error);
     }
    }

    const sendEmailNotification = async(data,token) => {
        try {
          await customAxios.post('/send-email-notification', { ...data },{
            headers: { Authorization: `Bearer ${token}`}
          })
        } catch (error) {
          return alert('Error sending email notification')
        }
    }

    const handleMessageSubmit = async(channelID,email,messageText,isAuthorisationCode) => {
        let today = new Date();
        const codeExp = today.setMonth(today.getMonth() + 2)

        const input = {
          channelID: channelID,
          author: email,
          body: messageText.trim(),
          receiver: channelID.split('_')[0],
          senderName: nickname,
          dateTime: codeExp/1000
        };

        dispatch(sendMessageRequest({ message: input }))
        const currentMessages = messagesRef.current
        if (todayRef.current) {
          setMessages({...messages,items:[...currentMessages, input]});
          messagesRef.current = [...currentMessages,input]
        } else {
          setMessages({...messages,items:[...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, input] });
          messagesRef.current = [...currentMessages,  { type: 'date', value: 'Today', createdAt: Math.random() }, input]
          todayRef.current = true
        }
        
        try {
          setMessageText('')
          const authToken = await getAccessToken()
          await API.graphql(graphqlOperation(createMessage, { input }, { authToken }))
        } catch (error) {
          dispatch(sendMessageError({ message: input }))
        }

        try {
          if (token) {
            await sendMessagePushNotification()
          } else {
            const accessToken = await getAccessToken()
            await sendEmailNotification(input,accessToken)
          }
        } catch (error) {
          console.log('Error sending  notifications')
        }

        // try {
        //   const accessToken = await getAccessToken()
        //   await sendEmailNotification(input,accessToken)
        // } catch (error) {
        //   return alert('Error sending Email Notification')
        // }
      }

    const sendAuthorisationCode = async() => {
      try {
        setIsModalOpen(false)
        setAuthorisationCode({ creating: true, created: false, error: '' })
        let authorisationCode = 'bac_'.concat(translator.fromUUID(uuidv4()).substring(1,6))
        authorisationCodeRef.current = authorisationCode
        const authToken = await getAccessToken()
        await customAxios.post('/booking/authorise', { authorisationCode, clientEmail: chatInfo.channelID.split('_')[0] },{
          headers: { Authorization: `Bearer ${authToken}`}
        })
        setAuthorisationCode({ creating: false, created: true, error: '' })
      } catch (error) {
        console.log(error);
        setAuthorisationCode({ creating: false, created: false, error: 'Error' })
      }
      }

  return (
    <div className='messages'>        
      <div className="messages__container">
          <Alert >Chat is cleared on Booking Checkout</Alert>
        <div className="messages__list">
          {
            messages?.items?.length ? <div className="messages__scroller">
            { 
              messages.items.map((message) => <Message key={message.createdAt} message={message} />) }
            <div ref={messagesEndRef} />
        </div> : (
          <Container>
            { messages.loading ? <Note>loading messages..</Note>:
             messages.err ? <SubContainer>
                              <Error>Error fetching messages</Error>
                              <MdRefresh size={20} cursor={'pointer'} onClick={refetch} />
                            </SubContainer>: 
            !messages.items.length ? <Note>No chat messages</Note>: null }
          </Container>
        )}
        </div>
        { !authorisationCode.created && !isBookingAuthorised && !messages.loading? <>
            <FormButton background='#5282BD' 
                        color='white' 
                        border='none'
                        disabled={authorisationCode.creating}
                        onClick={() => setIsModalOpen(true)}>
                          { authorisationCode.creating ? 'Sending code..': 'Send Authorisation Code' }
            </FormButton>
            { authorisationCode.error ? <p style={{ color: 'red', textAlign: 'center' }}>Failed to create Authorisation Code. Try again</p>: null }
        </>: null }
        <div className="messages__chat">
            <form onSubmit={onSubmit} className='messages__form'>
                <input className='messages__input'
                type="text"
                name="messageBody"
                placeholder="Type your message here"
                onChange={handleOnChange}
                value={messageText}
                />
                <BiSend size={20} onClick={onSubmit} />
            </form>
        </div>
    </div>
    {
                            isModalOpen ? (
                                <InteractiveModal height='90vh'> 
                                    <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>Send Authorisation Code Once to client once you have agreed on a Date. This code will enable them to create a Booking. Continue?</div>
                                    
                                    <div className="imodal__btns">
                                        <FormButton background='#5282BD' 
                                                    color='white' 
                                                    onClick={sendAuthorisationCode}>
                                                    Continue
                                        </FormButton>
                                        <FormButton borderRadius='5px' 
                                                    onClick={() => setIsModalOpen(false)}>
                                                        Cancel
                                        </FormButton>
                                    </div>
                                    
                                </InteractiveModal>
                                ) : null 
                            }
  </div>
  )
}

const InitialChatScreen = () => {

  return (
    <div className="dinit">
      <div className="dinit__container">
        <p>Click on any chat to view messages</p>
        <BiMessageSquareDots size={30}/>
      </div>
    </div>
  )
}

const NoChats = () => {

  return (
    <div className="dinit">
      <div className="dinit__container">
        <p>No chats...yet</p>
      </div>
    </div>
  )
}

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`

const DashboardChat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [active, setActive] = useState(null)
  const [chatInfo, setChatInfo] = useState({ chatEmail: '', channelID: '' })
  const { chats, reload } = useGetChatsList()

  const openChat = (email,index) => {
    setChatInfo({ chatEmail: email, channelID: email.concat('_vm') })
    setIsChatOpen(true)
    setActive(email)
  }

  const refresh = () => {
    setIsChatOpen(false)
    setActive(null)
    reload()
  }

  return (
    <div className='dchat__container'>
      <div className="dtitle">
          <div className="dboard">
                <MdChat size={40} color={'#010A58'} />
                <p className='dboard__text'>Chats</p>
          </div>
          <AiOutlineReload size={25} color='#010A58' cursor='pointer' onClick={refresh} />
      </div>
      <p style={{ marginBottom: 10}}>*Connection will close automatically after 10 minutes of inactivity</p>
      <div className='dchat'>
        <div className='dchat__profiles'>
          { chats.loading ? <SkeletonContainer>
                          <Skeleton height='100%' />
                      </SkeletonContainer> :
            chats.err ? <Error>Error fetching chats</Error> :
            !chats.items.length ?  <NoChats /> : chats.items.map((email,idx)=>{
                                                            return <div key={email} 
                                                                        style={{cursor:'pointer'}} 
                                                                        className={active === email ? 'profile__active': null} 
                                                                        onClick={()=>openChat(email,idx)}
                                                                    >
                                                                      <Profile email={email} items={chats.items} />
                                                                  </div>
          })}
        </div>
        { isChatOpen ? <Chat chatInfo={chatInfo} height='60vh' /> : <InitialChatScreen />}
      </div>
    </div>
  )
}

export default DashboardChat
