import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  renewing : false,
  renewSuccess: null,
  renewError: ''
}

const renewSubscription = createSlice({
  name: 'renewSubscription',
  initialState,
  reducers: {
    renewSubscriptionRequest(state) {
      state.renewing = true
    },
    renewSubscriptionSuccess(state, action) {
        state.renewing = false
        state.renewSuccess = true
    },
    renewSubscriptionError(state, action) {
      state.renewing = false
      state.renewError = action.payload
    },
  }
})

export const { renewSubscriptionRequest, renewSubscriptionSuccess, renewSubscriptionError } = renewSubscription.actions

export const renewCancelledSubscription = (data, token) => async (dispatch) => {
  dispatch(renewSubscriptionRequest())
  try {
      await customAxios.post('/subscriptions/renew',{ ...data },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      dispatch(renewSubscriptionSuccess())                                                           
  } catch(err)  {
      dispatch(renewSubscriptionError({ message: 'Unauthorized' }))
  }      
}



export default renewSubscription.reducer
