import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { API, graphqlOperation } from 'aws-amplify';
import { useAuthState } from "react-firebase-hooks/auth";
import { getChatsListByAuthor, messagesByChannelID } from "../../graphql/queries";
import { auth } from "../../firebase/firebase";
import { setUnreadMessages } from "../badge/badge.slice";

export const ChatBadgeContext = React.createContext()

export const ChatBadgeContextProvider = ({ children }) => {
    const [user] = useAuthState(auth);
    const [chats, setChats] = useState([])
    const [fetched, setFetched] = useState(false)
    const unreadChats = useRef({})
    const dispatch = useDispatch()

    const getLastMessage = async(items,receiver) => {
      let dblastMessage
      const chatMessages = items.filter(item => {
          return ((item.author === user.email && item.receiver === receiver) || (item.receiver === user.email && item.author === receiver)) 
          })
      dblastMessage =  chatMessages[chatMessages.length - 1]
      return dblastMessage   
    }

    useEffect(() => {
      if (fetched) {
        dispatch(setUnreadMessages(unreadChats.current))
      }
    },[fetched])

    useEffect(()=>{
      if (user) {
        API
        .graphql(graphqlOperation(getChatsListByAuthor, {
            author: user.email,
            sortDirection: 'DESC',
          },{ authToken: user.accessToken }))
          .then((response) => {
            const items = response?.data?.getChatsListByAuthor?.items;
            if (items && items.length) {
            let msgRecepeints = []
            for (const item of items) {
                if (!msgRecepeints.includes(item.receiver) && item.channelID.includes('_vm')) { 
                    msgRecepeints.push(item.receiver)
                }
            }
            setChats([...msgRecepeints]);
            }
          })
      }
    },[user])

      useEffect(()=>{
        const findUnreadMessages = async() => {
          try {
            const channelID = user.email.concat('_vm')
            const response = await API.graphql(graphqlOperation(messagesByChannelID, {
                channelID,
                sortDirection: 'ASC'
              },{ authToken: user.email }))
              const items = response?.data?.messagesByChannelID?.items;
            const unresolvedLastMessages = chats.map(async(email)=>{
                const lastMessage = await getLastMessage(items, email)
                return lastMessage
              })
              const lastMessages = await Promise.all(unresolvedLastMessages)
              for (const msg of lastMessages) {
                const { read, author } = msg
                if (author !== user.email) {
                  if (!read) {
                    unreadChats.current[author] = author
                  }
                }
              }
              setFetched(true)
          } catch (error) {
            console.log(error);  
          } 
        }

        if (chats.length) {
             findUnreadMessages()
        }
    },[chats])


    return (
        <ChatBadgeContext.Provider value={{}}>
            {children}
        </ChatBadgeContext.Provider>
    )
}