import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  token: null,
  tokenError: ''
}

const fcmToken = createSlice({
  name: 'fcmToken',
  initialState,
  reducers: {
    fetchedFCMTokenRequest(state) {
      state.loading = true
    },
    fetchedFCMTokenSuccess(state, action) {
        state.loading = false
        state.token = action.payload
    },
    fetchedFCMTokenError(state, action) {
      state.loading = false
      state.tokenError = action.payload
    },
    fetchedFCMTokenReset(state) {
      state.loading = false
      state.tokenError = ''
      state.token = null
    },
  }
})

export const { fetchedFCMTokenRequest, fetchedFCMTokenSuccess, fetchedFCMTokenError, fetchedFCMTokenReset } = fcmToken.actions

export const fetchFCMPushToken = (email) => async (dispatch) => {
  dispatch(fetchedFCMTokenRequest())
  try {
      const { data: { Items } } = await customAxios.get('/fcm-push-token',{ params: { email, userType: 'client' }});
      dispatch(fetchedFCMTokenSuccess(Items[0].token))
  } catch (error) {
      dispatch(fetchedFCMTokenError(error.message))
  }      
}

export default fcmToken.reducer
