import { useHistory } from "react-router-dom"
import { AiFillCloseSquare } from "react-icons/ai";
import styled from "styled-components";

const Close = styled(AiFillCloseSquare)`
    align-self: end;
    cursor: pointer;
`

const Modal = ({message,link,warning}) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(`/${link}`)
  }

  return (
    <div className={`modal ${warning ? 'modal__warn' : null}`}>
      <p className='modal__p'>{message}</p>
      {link && <p onClick={handleClick} className="modal__link">view</p>}
    </div>
  )
}

export const InteractiveModal = ({ children, height, style, setIsModalOpen, onClose }) => {

  const closeModal = () => {
    setIsModalOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <div className="imodal">
      <div className="imodal__overlay" style={{ height, ...(style && {...style }) }}>
        <div className="imodal__content">
          { setIsModalOpen ? <Close size={15} onClick={closeModal} />: null }
          { children }
        </div>
      </div>
    </div>
  )
}

export default Modal
