import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  MdOutlineDirectionsCarFilled, MdEdit } from 'react-icons/md'
import {  BsTrash, BsFillCheckSquareFill } from 'react-icons/bs'
import { HiOutlineMail } from 'react-icons/hi'
import { BiUser } from 'react-icons/bi'
import useCollapse from 'react-collapsed'
import { useAuth0 } from '@auth0/auth0-react'

import { Button } from '../button/Button'
import { InputFieldReq } from '../field/field'
import { textValidator, emailValidator } from '../../utils/validators'
import { FormButton } from '../button/Button'
import { updateTower } from './tower.update.slice'
import { deleteTower, deleteTowerSuccess, deleteTowerReset } from './tower.delete.slice'
import { Uploading } from '../loader/loader'

const TowerUpdateSuccess = ({text}) => {

  return (
    <div className="tower__success">
          <BsFillCheckSquareFill color='green' />
          <p className='tower__success-text'>{text}</p>
    </div>
  )
}

function Tower({ tower }) {
  const { updating, towerUpdate, towerUpdateError } = useSelector(state => state.towerUpdate)
  const { deleting, towerDelete, towerDeleteError } = useSelector(state => state.towerDelete)
  const { companyID, companyName, fullName, email, vehicleRegistration } = tower
  const [data, setData] = useState({ fullName, companyName, vehicleRegistration, delete: '' })
  const { user: { sub }, getAccessTokenSilently } = useAuth0();
  const [formErrors, setFormErrors] = useState({})
  const [isDeleteExpanded, setIsDeleteExpanded] = useState(false)
  const [isUpdateExpanded, setIsUpdateExpanded] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const dispatch = useDispatch()

  const { getCollapseProps, getToggleProps } = useCollapse(isDeleteExpanded)
  const { getCollapseProps: getUpdateCollapseProps, getToggleProps: getUpdateToggleProps } = useCollapse(isUpdateExpanded)

  useEffect(() => {
    if (towerDelete) {
      setTimeout(() => {
        dispatch(deleteTowerSuccess(tower))
        dispatch(deleteTowerReset())
      },2000)
    }
  },[towerDelete])

  const handleUpdateSubmit =  async(e) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      })
      setBtnDisable(true)
      const postData = { item: { fullName: data.fullName,
                                vehicleRegistration: data.vehicleRegistration }, email, companyID }
      dispatch(updateTower(postData,sub,accessToken))
    } catch (error) {
      setBtnDisable(false)
    }
    
  }

  const handleDeleteSubmit = async() => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      })
      setBtnDisable(true)
      const data = { companyID, email }
      dispatch(deleteTower(data,sub,accessToken))
    } catch (error) {
      setBtnDisable(false)
    }
    
  }

  const onChange = async(e) => {
    const { name, value } = e.target
    setData({...data, [name]: value})
    if (name==='companyName' || name==='fullName' ) {            
      textValidator(value) ? setFormErrors({...formErrors,[name]:'Only letters and spaces are allowed'}) : delete formErrors[name]
    } else if (name==='email') {
        emailValidator(value) ? setFormErrors({...formErrors,[name]:'Invalid email'}) : delete formErrors[name]
    }
  }

  return (
    <div className="tower">
      <div className='tower__container'>
        <div className="tower__values">
          <div className="tower__row">
            <div className="tower__item">
                  <BiUser size={20} color='#010A58' />
                  <p className="tower__prop">{fullName}</p>
            </div>
            <div className="tower__item">
              <MdOutlineDirectionsCarFilled size={20} color='#010A58' />
              <p className="tower__prop">{vehicleRegistration}</p>
            </div>
            <div className="tower__item">
                <HiOutlineMail size={20} color='#010A58' />
                <p className="tower__prop">{email}</p>
            </div>
          </div>
        </div>
        <div className="tower__actions">
          { !isDeleteExpanded ? <MdEdit color='#010A58'
                  size={30}
                  cursor={'pointer'}
                  {...getUpdateToggleProps({ onClick: () => setIsUpdateExpanded(prev => !prev),})}
                  /> : null }
          { !isUpdateExpanded ? <BsTrash color='red'
                                          size={30}
                                          cursor={'pointer'}
                                          {...getToggleProps({ onClick: () => setIsDeleteExpanded(prev => !prev),})}
                                          /> : null }
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="tower__delete"  >
          <input
              type='text'
              value={data.delete}
              name='delete'
              className="tower__input"
              placeholder="type 'DELETE' to confirm delete"
              onChange={onChange}
              />
          { data.delete === 'DELETE' ? <Button borderRadius='7px'
                                               background='red' onClick={handleDeleteSubmit} 
                                               disabled={btnDisable}
                                               color='white' 
                                               border='none'>Delete
                                        </Button> : null }
        </div>
        { (deleting || towerDeleteError || towerDelete) ? <div className="tower__progress">
            { 
              deleting ? <Uploading text="deleting" color={'black'} /> : towerDeleteError ? 
              <p className='error'>{towerDeleteError}</p> :
              towerDelete ? <TowerUpdateSuccess text='Deleted successfully' /> : null
            }
        </div> : null }
      </div>
        <div className="tower__update" {...getUpdateCollapseProps()}>
            <p className='form__h'>Update Tower Profile</p>
            <div className="tower__fields">
              <InputFieldReq  label='fullName' 
                              value={data.fullName} 
                              name='fullName' 
                              placeholder='John Doe' 
                              onChange={onChange} 
              />
              <InputFieldReq label='vehicleRegistration' 
                            value={data.vehicleRegistration} 
                            name='vehicleRegistration' 
                            placeholder='ZZZZZZGP' 
                            onChange={onChange} 
              /> 
            </div>
            <div className='tower__btns'>
                <FormButton disabled={btnDisable} 
                            background='#5282BD' 
                            color='white' 
                            onClick={handleUpdateSubmit}>
                              Update Tower Profile
                </FormButton>
            </div>
            <div className="tower__progress">
              { 
                updating ? <Uploading text="updating" color={'black'} /> : towerUpdateError ? 
                <p className='error'>{towerUpdateError}</p> :
                towerUpdate ? <TowerUpdateSuccess text='Updated successfully' /> : null
              }
            </div>      
        </div>
    </div>
  )
}

export default Tower