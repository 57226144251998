import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  fetching : false,
  link: null,
  linkError: ''
}

const updateSubscription = createSlice({
  name: 'updateSubscription',
  initialState,
  reducers: {
    fetchedSubscriptionUpdateLinkRequest(state) {
      state.fetching = true
    },
    fetchedSubscriptionUpdateLinkSuccess(state, action) {
        state.fetching = false
        state.link = action.payload
    },
    fetchedSubscriptionUpdateLinkError(state, action) {
      state.fetching = false
      state.linkError = action.payload
    },
    fetchedSubscriptionUpdateLinkReset(state) {
      state.fetching = false
      state.linkError = ''
      state.link = null
    },
  }
})

export const { fetchedSubscriptionUpdateLinkRequest, fetchedSubscriptionUpdateLinkSuccess, 
               fetchedSubscriptionUpdateLinkError, fetchedSubscriptionUpdateLinkReset } = updateSubscription.actions

export const fetchSubscriptionUpdateLink = (subscription_code, token) => async (dispatch) => {
  dispatch(fetchedSubscriptionUpdateLinkRequest())
  try {
      const { data: { link } } = await customAxios.post('/subscriptions/subscription/update',{ subscription_code },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
       if (link) {
        dispatch(fetchedSubscriptionUpdateLinkSuccess(link))
       } else {
        dispatch(fetchedSubscriptionUpdateLinkError({ message: 'Error' }))
       }                                                           
  } catch(err)  {
      dispatch(fetchedSubscriptionUpdateLinkError({ message: 'Unauthorized' }))
  }      
}



export default updateSubscription.reducer
