import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  bookings: [],
  bookingsError: '',
  lastEvaluatedKey: null
}

const bookings = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    fetchBookingsRequest(state) {
      state.loading = true
    },
    fetchedBookingsSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.bookings = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMoreBookingsSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.bookings = [...state.bookings,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
    },
    fetchedBookingsError(state, action) {
        state.loading = false
        state.bookingsError = action.payload
      },
    bookingStateUpdateSuccess(state, action){
      state.bookings = action.payload
    }
  }
})

export const { fetchBookingsRequest, fetchMoreBookingsRequest, fetchedMoreBookingsSuccess,
        fetchedBookingsError, fetchedBookingsSuccess, bookingStateUpdateSuccess } = bookings.actions

export const fetchBookings = (email, state, id, token, startDate, endDate, q) => async (dispatch) => {
  dispatch(fetchBookingsRequest())
  try {
      const { data } = await customAxios.get('/bookings',{ 
          params: { email, state, id, startDate: startDate?.getTime(), endDate: endDate?.getTime(),q: q.toLowerCase() }
      , headers: { 'Authorization': `Bearer ${token}` } });
      const { Items, LastEvaluatedKey } = data
      dispatch(fetchedBookingsSuccess({ Items, LastEvaluatedKey }))
  } catch (error) {
      dispatch(fetchedBookingsError(error.message))
  }      
}
      
export const bookingsPaginationUpdate = (email, state, lastEvaluatedKey, id, token, startDate, endDate, q) => async (dispatch) => {
    try {
        const { data } = await customAxios.get('/bookings',{ 
            params: { email, state, lastEvaluatedKey, id, startDate: startDate.getTime(), endDate: endDate.getTime(), q }, 
            headers: { 'Authorization': `Bearer ${token}` } });
        const { Items, LastEvaluatedKey } = data
        dispatch(fetchedMoreBookingsSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedBookingsError(error.message))
    }
}

export default bookings.reducer