import React from 'react'
import { TagFieldReq } from '../field/field';
import { Button } from '../button/Button';
import {AiOutlineClose} from 'react-icons/ai'

function TagInput({label,value,name,id,handleOnChange,handleTagAdd,handleTagUpdate,data,placeholder,options,required}) {
    return (
        <div>   
            <TagFieldReq label={label} value={value} id={id} required={required} 
                         name={name} onChange={handleOnChange} options={options}
                         handleOptionAdd={handleTagAdd} placeholder={placeholder} />
            <div className='form__item' >
                <div style={{color:'#f9f9f9'}}>placeholder</div>
                <div style={{textAlign:'right'}}>
                {data.map(tag => {
                            return (
                                <div className='tag' key={Math.random()} >
                                <Button disabled={true} borderRadius='5px' >{tag}</Button>
                                <AiOutlineClose size={10} color='red' className='tag__i' onClick={(e)=>handleTagUpdate(e,tag,id)}  />
                                </div>
                            );
                    })}
                </div>
            </div>
        </div>
    )
}

export default TagInput
