import React, { useContext, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getCookieConsentValue } from "react-cookie-consent";

import { FirebaseContext } from '../../firebase/firebase.context'
import { auth } from '../../firebase/firebase'
import { Button } from '../../Components/button/Button'
import { Loading } from '../../Components/loader/loader'

const DialogContainer = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const ImgContainer = styled.div`
    width: 50px;
    height: 50px;
`
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

const Caption = styled.p`
    font-weight: bold;
`

const AlreadyLoggedInError = styled.p`
    text-align: center;
    color: red;
    margin-top: 50px;
`

function Login() {
    const { signInWithGoogle } = useContext(FirebaseContext)
    const [user, loading, error] = useAuthState(auth);
    const { user: auth0User } = useAuth0();
    const { search, state } = useLocation()
    const history = useHistory()
    const { next, token, amount, transactionReference, bankReference, paymentOption, clientName, companyID } = queryString.parse(search)
    let consentValue = getCookieConsentValue('auth')

    useEffect(()=>{
        if (user) {
            if (next) {
                history.push(`${next}${token ?`?token=${token}` : ''}${amount ? `&amount=${amount}&bankReference=${bankReference}&transactionReference=${transactionReference}&paymentOption=${paymentOption}&clientName=${clientName}&companyID=${companyID}`: ''}`,{ ...(state && state)})
            } 
        } else if (error) {
            alert(`Authentication fail: ${error.message}`)
        }
    },[user])
   
  if (consentValue === 'false') {
    window.alert('Login feature uses 3rd Party Cookies. This feature has been disabled as you did not allow use of 3rd party Cookies.')
    return history.push('/')
  }

  return (
        <>
            {
                auth0User ? <AlreadyLoggedInError>Service Provider Account already logged in!</AlreadyLoggedInError> :
                loading ? <Loading loading={true} /> :
                <div className="login">
                    <div className="login__overlay">
                        <div className="login__content">
                            <DialogContainer>
                                <ImgContainer>
                                    <Img src='/notif-icon.webp' alt="tas-logo" />
                                </ImgContainer>
                                <Caption>Continue to TapAService</Caption>
                                <div className="login__btns">
                                    <Button background='#5282BD' 
                                                color='white' 
                                                borderRadius="5px"
                                                onClick={() => signInWithGoogle()}>
                                                Login with Google
                                    </Button>    
                                </div>
                            </DialogContainer>
                        </div>
                    </div>
                </div>
            }
        </>
  )
}

export default Login