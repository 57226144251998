import React from 'react'
import { useHistory } from 'react-router-dom'

const containerStyle = {
  paddingLeft: '50px'
}

const headingStyle = {
  marginTop: '35px',
  marginBottom: '35px',
  color: 'green',
  fontSize: '20px',
  fontWeight: 700
}

const textStyle = {
  lineHeight: '30px',
  fontWeight: 600
}

const CTA = {
  padding: '10px',
  color: 'white',
  border: 'none',
  background: 'green',
  borderRadius: '8px',
  marginTop: '50px',
  cursor: 'pointer'
}

function SignupSuccess() {
  const history = useHistory()
    
  return (
    <div style={containerStyle} >
        <h2 style={headingStyle}>Account created Successfully!</h2>
        <div style={textStyle}>You have successfully created your account. A verification link has been sent to your email. <br/>
        You should verify your email address before proceeding any further. <br/>
        The next step is to create a Service Provider Profile. <br/>
        You are required to prepare the following before you can create your Profile: <br/>
          <ol style={{ paddingLeft: '50px'}}>
            <li>Company Information ( name, email etc )</li>
            <li>Company Logo</li>
            <li>Images ( workshop images, work-in-progress images )</li>
            <li>Company Promo Video</li>
          </ol>
        It is still possible to create a Profile without assets ( images, promo vide ) but it won't be active until you add all the required media.
        </div>
        <button style={CTA} onClick={() => history.push('/services/create')}>
          Create Service Provider Profile
        </button>
    </div>
  )
}

export default SignupSuccess