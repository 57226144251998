import { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import Compressor from 'compressorjs';

import { textSearch } from "./map"
import { textValidator, emailValidator, lengthValidator, yearValidator,
        passwordLengthValidator, passwordTextValidator, numberValidator } from "./validators"

const useGetFormValuesAndMethods = (profile,arrayData) => {
    const { location } = useSelector(state => state.location)
    const [formData, setFormData] = useState({
        image:'', companyName:'', password:'', statement:'', make:'',
        model:'', mileage:'', phone:'', rate: { flatBed: '', wheelLift: '', hookChain: '' }, liabilityCover:'', email:'', 
        sAddress:'', cPassword:'', vin:'', service:'', modelYear:'', vinNumber: '',
        sLocation:'', fullName:'', tel:'', companyID:'', price: '', vehicleRegistration: '' })
    const [serviceTiers, setServiceTiers] = useState({ minor:[], major:[], exclusions:[] })
    const [address, setAddress] = useState([''])
    const [phoneNo, setPhoneNo] = useState([''])
    const [services, setServices] = useState([''])
    const [selectedImage, setSelectedImage] = useState(null)
    const [images, setImages] = useState([])
    const [video, setVideo] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [lengthValidation, setLengthValidation] = useState({})
    const [autocomplete, setAutocomplete] = useState([])

    useEffect(()=>{
        if (profile) {
            setFormData({...formData, image:profile.logo, companyName:profile.companyName, statement:profile.statement,
                rate:profile.rate, liabilityCover:profile.liabilityCover, email:profile.email, price: profile.price })
            setAddress(profile.address)
            setPhoneNo(profile.phoneNo)
            profile.services && setServices(profile.services)
            profile.serviceTiers && setServiceTiers({ minor:profile.serviceTiers.minor,
                                                      major:profile.serviceTiers.major, 
                                                      exclusions:profile.serviceTiers.exclusions })
        }

        if (arrayData) {
            setPhoneNo(arrayData.phoneNo)
        }
    },[])
    
    useEffect(()=>{
        selectedImage && setFormData({...formData,image:selectedImage})
    },[selectedImage])

    const handleOnChange = async(e) => {
        const {name, value, id } = e.target
        if (name==='input') {
            setFormData({...formData,input:{...formData.input,[id]:value}})
       } else {
            setFormData({...formData,[name]:value})
            if ((name==='sAddress' || name==='sLocation') && value.length > 3) {
                const values = await textSearch(value,location?.textualInfo?.country)
                const suggestions = values.map(({label}) => {
                    return label
                 })
                 setAutocomplete(suggestions)
            }
            if (name==='password') {      
                passwordLengthValidator(value) ? setFormErrors({...formErrors,[name]:{...formErrors[name],len:'Minimum of 8 characters'}}) : delete formErrors[name].len
                passwordTextValidator(value) ? setFormErrors(prev => ({...prev,[name]:{...prev[name],chars:'Uppercase, lowercase, numerical & special chars'}})) : delete formErrors[name].chars
            } else if (name==='companyName' || name==='fullName' || name==='make' || name==='statement') {            
                textValidator(value) ? setFormErrors({...formErrors,[name]:'Only letters and spaces are allowed'}) : delete formErrors[name]
                if (name==='companyName') {
                    lengthValidator(value, 25) ? setLengthValidation({...lengthValidation, [name]: 'Company Name should not exceed 25 characters' }):  delete lengthValidation[name]
                }
                if (name==='statement') {
                    lengthValidator(value, 100) ? setLengthValidation({...lengthValidation, [name]:"Character count shouldn't exceed 100" }) : delete lengthValidation[name]
                } 
            } else if (name==='email') {
                emailValidator(value) ? setFormErrors({...formErrors,[name]:'Invalid email'}) : delete formErrors[name]
                setFormData({...formData,[name]:value.toLowerCase()})
            } else if (name==='mileage' || name==='tel' || name ==='price') {
                numberValidator(value) ? setFormErrors({...formErrors,[name]:'Only numbers are allowed'}) : delete formErrors[name]
            } else if (name==='modelYear') {
                yearValidator(value) ? setFormErrors({...formErrors,[name]:'Valid year format only'}) : delete formErrors[name]
              }
        }
        }

    const handleItemChange = async (e,idx) => {
        const {value,name} = e.target
        if (name === 'address') {
        const newValues = [...address]
        newValues.splice(idx,1,value)
        setAddress([...newValues])
        if (value.length > 3) {
            const values = await textSearch(value, location?.textualInfo?.country)
            const suggestions = values.map(({label}) => {
                return label
            })
            setAutocomplete(suggestions)
        }
        } else if (name==='phone' && !isNaN(value)) {
        const newValues = [...phoneNo]
        newValues.splice(idx,1,value)
        setPhoneNo([...newValues])
        }  else {
        const newValues = [...services]
        newValues.splice(idx,1,value)
        setServices([...newValues])
        }
    }

    const handleItemAdd = (name) => {
        if (name === 'address') {
            setAddress([...address,''])
          }
          else if (name === 'phone'){
            setPhoneNo([...phoneNo,''])
          } else {
            setServices([...services,''])
          }
    }

    const handleItemRemove = (name,idx) => {
        if (name === 'address') {
        const newValues = [...address]
        newValues.splice(idx,1)
        setAddress([...newValues])
        } else if (name === 'phone') {
        const newValues = [...phoneNo]
        newValues.splice(idx,1)
        setPhoneNo([...newValues])
        }  else {
        const newValues = [...services]
        newValues.splice(idx,1)
        setServices([...newValues])
        } 
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        if (file.size < 100000) {
            new Compressor(file, {
                quality: 0.8,
                success: (compressedResult) => {       
                    setSelectedImage(compressedResult)
                },
              });
            delete formErrors['logo']
        } else {
            setFormErrors({...formErrors,logo:'Max size of 100kB exceeded'})
        }
    }

    const handleImagesChange = (images) => {
        setImages(images)
    }

    const handleVidChange = (video) => {
        setVideo(video)
    }

    const handleTagAdd = (e,id,value) => {
        const existingTags = serviceTiers[id]
        if (!existingTags.includes(value)) {
            setServiceTiers({...serviceTiers,[id]:[...existingTags,value]})
        }
    }

    const handleTagUpdate = (e,supplied,id) => {
        const newTags = serviceTiers[id].filter(tag => tag !== supplied)
        setServiceTiers({...serviceTiers,[id]:[...newTags]})
    }

    return [
            {
                selectedImage,images,video,autocomplete,formData,serviceTiers,phoneNo,address,services
            },
            { handleImageChange, handleImagesChange, handleItemAdd, handleItemChange, handleItemRemove,
              handleTagAdd, handleTagUpdate, handleVidChange, handleOnChange, setAddress, setFormData
            },
            formErrors,
            lengthValidation
        ]
}

export default useGetFormValuesAndMethods