import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  delete: {
      isTowerDeleted: false,
      tower: null
  },
  create: {
      isTowerCreated: false,
      tower: null
  }   
}

const towerCRUD = createSlice({
  name: 'towerCRUD',
  initialState,
  reducers: {
    towerCreateSuccess(state, action) {
      state.create.isTowerCreated = true
      state.create.tower = action.payload
    },
    towerDeleteSuccess(state, action) {
        state.delete.isTowerDeleted = true
        state.delete.tower = action.payload
    },
    towerStateReset(state, action) {
      state.create.isTowerCreated = false
      state.create.tower = null
      state.delete.isTowerDeleted = false
      state.delete.tower = null
    },
  }
})

export const { towerCreateSuccess, towerDeleteSuccess, towerStateReset } = towerCRUD.actions


export default towerCRUD.reducer
