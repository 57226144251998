import React, {useState} from 'react';

const baseURL = 'https://tapaservice.s3.amazonaws.com'

function ImageViewer({images}) {
    
    const [active, setActive] = useState(images[0])

    const openImage = (index) => {
        for (let idx = 0; idx < images.length; idx++) {
            if (idx === index) {
                setActive(images[idx])
            }          
        }
    }
  return (
  <div className='viewer'>
      {active ? <img className='viewer__open' src={`${baseURL}/${active}`} alt="viewer-open" /> : null}
      <div className='viewer__tiles'>
          {images.map((img,idx) => {
              return <img key={idx} src={`${baseURL}/${img}`} className={`viewer__tile ${img === active ? 'viewer__active' : null} ${idx === 0 ? 'viewer__first' : null}`} onClick={()=>openImage(idx)} alt="tile-icon" />
          })}
      </div>
  </div>
  )
}

export default ImageViewer;
