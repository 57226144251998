import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  towers: [],
  towersError: '',
  lastEvaluatedKey: null
}

const towers = createSlice({
  name: 'towers',
  initialState,
  reducers: {
    fetchTowersRequest(state) {
      state.loading = true
    },
    fetchedTowersSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.towers = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMoreTowersSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.towers = [...state.towers,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
    },
    fetchedTowersError(state, action) {
        state.loading = false
        state.loadingMore = false
        state.towersError = action.payload
      },
    towersUIUpdate(state, action) {
      state.towers = action.payload
    }
  }
})

export const { fetchTowersRequest, fetchedMoreTowersSuccess,
        fetchedTowersError, fetchedTowersSuccess, towersUIUpdate } = towers.actions

export const fetchTowers = (companyEmail, id, token) => async (dispatch) => {
    dispatch(fetchTowersRequest())
    try {
        const {data} = await customAxios.get('/towers',{ params: { companyEmail, id }, headers: { 'Authorization': `Bearer ${token}` } });
        const { Items, LastEvaluatedKey} = data
        dispatch(fetchedTowersSuccess({ Items, LastEvaluatedKey}))
    } catch (error) {
        dispatch(fetchedTowersError(error.message))
    }      
}

export default towers.reducer