import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
  fetching : false,
  data: {},
  fetched: false,
  fetchError: ''
}

const preUpload = createSlice({
  name: 'preUpload',
  initialState,
  reducers: {
    fetchPreUploadDataRequest(state) {
      state.fetching = true
    },
    fetchPreUploadDataSuccess(state, action) {
      state.fetching = false
      state.fetched = true
      state.data = action.payload
    },
    fetchPreUploadDataError(state, action) {
      state.fetching = false
      state.fetchError = action.payload
    },
    fetchPreUploadDataReset(state) {
      state.fetchError = ''
      state.fetched = false
      state.fetching = false
      state.data = {}
    },
  }
})

export const { fetchPreUploadDataError, fetchPreUploadDataRequest, 
              fetchPreUploadDataReset, fetchPreUploadDataSuccess } = preUpload.actions


export const fetchPreUploadData = (token,email) => async (dispatch) => {
  try {
    dispatch(fetchPreUploadDataRequest())
    const {data} = await customAxios.get(`/s3-policy-document`,{
                                        params: {email},
                                        headers: { Authorization: `Bearer ${token}`}
                                                            });                                    
    dispatch(fetchPreUploadDataSuccess(data))
} catch (error) {
    console.log(error);
    dispatch(fetchPreUploadDataError(error))
}
}


export default preUpload.reducer
