import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SubscriptionSkeleton() {
  return (
    <div>
        <div>
            <Skeleton height={30} width={'30%'} />
            <div style={{ marginTop: 25}}>
                <Skeleton height={10} width={100} />
            </div>
        </div>
        <div style={{ height: '500px', marginTop: 25}}>
            <Skeleton height={'100%'} width={'90%'} />    
        </div>
    </div>
  )
}

export default SubscriptionSkeleton