import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import AuthButton from '../authButton/authButton';
import { useAuth0 } from '@auth0/auth0-react';
import {useHistory} from 'react-router-dom'
import { GiHamburgerMenu, GiCancel } from 'react-icons/gi'
import { MdArrowDropDown, MdArrowDropUp, MdOutlineMiscellaneousServices, 
          MdDashboard, MdSwapHorizontalCircle } from "react-icons/md";

import { updateAuth0Check, updateFirebaseCheck } from './auth.slice';
import { openSubMenu, closeSubMenu } from './submenu.slice';
import { addMetadata } from './metadata.slice';
import Skeleton from 'react-loading-skeleton'
import { auth } from '../../firebase/firebase';
import Badge from '../badge/Badge';
import { fetchBookingDetail } from '../booking/booking.detail.slice';
import { FirebaseContext } from '../../firebase/firebase.context';
import { resetProfileType } from '../dashboard/redux/profile.detail.slice';

const HamburgerMenu = styled(GiHamburgerMenu)`
  cursor: pointer;
  @media (min-width: 850px) {
    display: none;
  }
`

const Close = styled(GiCancel)`
  @media (min-width: 850px) {
    display: none;
  }
`

const Button = styled.div`
  cursor: pointer;
`

const LinksContainer = styled.div`
  display: none;
  @media (min-width: 850px) {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
  }
`

const Link = styled.div`
  position: relative;
  padding: 6px;
  font-weight: 600;
  &:hover {
    color: gray;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 5px;
  }
`

const ServicesLink = styled.p`
  cursor: pointer;
  font-weight: 600;
  &:hover {
    color: gray;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 5px;
  }
`

const Brand = styled.p`
  font-weight: 600;
`

const SubContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: none;
  @media (min-width: 600px) {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`

const Hamburger = styled(GiHamburgerMenu)`
  cursor: pointer;
  @media (min-width: 600px) {
    display: none;
  }
`

const ClientMenu = ({ setIsDropDownOpen, isDropDownOpen }) => {
  const { unreadMessages } = useSelector(state => state.unreadMessages )
  const { booking } = useSelector(state => state.bookingDetail)
  const [selected, setSelected] = useState('')
  const history = useHistory()
  
   const toggleDropDown = () => {
    setIsDropDownOpen(prev => !prev)
   }
   
  return (
    <Button onClick={toggleDropDown}>
      {
        isDropDownOpen ? <Close size={20} /> : <HamburgerMenu size={20} />
      }
      <LinksContainer>
      <Link onClick={() => {
            setSelected('about')
            history.push('/about-us')
            }}
            className={selected === 'about' ? 'nav__selected': ''}>About Us</Link>
          <Link onClick={() => {
            setSelected('services')
            history.push('/services')
            }}
            className={selected === 'services' ? 'nav__selected': ''}>Services</Link>
          <Link onClick={() => {
            setSelected('chats')
            history.push('/chats')}}
            className={selected === 'chats' ? 'nav__selected': ''}>Chats { Object.keys(unreadMessages).length ? <Badge value={Object.keys(unreadMessages).length} />: null }</Link>
          <Link onClick={() => {
            setSelected('booking')
            history.push('/booking')}}
            className={selected === 'booking' ? 'nav__selected': ''}>Booking { booking ? <Badge value={1} /> : null }</Link>
      </LinksContainer>
    </Button>
  )
}

const UserDropdown = ({ setIsDropDownOpen }) => {
  const { logout: firebaseLogout } = useContext(FirebaseContext)
  const history = useHistory()

  return (
    <div className='dropdown'>
      <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/services')}
       } 
       className='dropdown__link'>Services</p>
      <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/chats')}
       }
       className='dropdown__link'>Chats</p>
      <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/booking')}
       }
       className='dropdown__link'>Booking</p>
       <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/')
        firebaseLogout()}
       }
       className='dropdown__link'>Logout</p>
        <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/subscriptions')}
       } 
       className='dropdown__link'>Subscriptions</p>
       <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/about-us')}
       } 
       className='dropdown__link'>About Us</p>
    </div>
  )
}

const Dropdown = ({ setIsDropDownOpen }) => {
  const history = useHistory()

  const login = () => {
    setIsDropDownOpen(false)
    history.push('/login?next=/')
  }

  return (
    <div className='dropdown'>
      <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/services')}
       } 
       className='dropdown__link'>Services</p>
        <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/subscriptions')}
       } 
       className='dropdown__link'>Subscriptions</p>
       <p onClick={() => {
        setIsDropDownOpen(false)
        history.push('/about-us')}
       } 
       className='dropdown__link'>About Us</p>
       <p onClick={login} 
       className='dropdown__link'>Login</p>
    </div>
  )
}

const SubMenu = ({setSelectedService}) => {
  const { coords } = useSelector(state => state.subMenu)
  const {profileType } = useSelector(state => state.selectedProfileType)
  const { user: { email } } = useAuth0();
  const container = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const applyCoords = useCallback(() => {
    const submenu = container.current
    const { bottom } = coords
    submenu.style.right = `5px`
    submenu.style.top = `${bottom}px`
  },[coords])

  useEffect(() => {
    applyCoords()
  },[coords])

  const goToDash = () => {
    setSelectedService(null)
    dispatch(closeSubMenu())
    history.push(`/dashboard/${encodeURIComponent(email)}`)
  }

  const createService = () => {
    setSelectedService(null)
    dispatch(closeSubMenu())
    history.push('/services/create')
  }

  const swapProfile = () => {
    dispatch(resetProfileType())
    history.replace(`/dashboard/${encodeURIComponent(email)}`)
  }

  return (
        <div className="dsubmenu" ref={container}>
          <div className="dsubmenu__item" onClick={createService} >
            <MdOutlineMiscellaneousServices size={20} />
            <p className="dsubmenu__text">Create Service</p>
          </div>
          {!Object.keys(profileType).length ? <div className="dsubmenu__item" onClick={goToDash}>
            <MdDashboard size={20} />
            <p className="dsubmenu__text">Go to Dashboard</p>   
         </div>:  <div className="dsubmenu__item" onClick={swapProfile}>
            <MdSwapHorizontalCircle size={20} />
            <p className="dsubmenu__text">Swap Profile</p>   
         </div> }
       </div>
  )
}



const NavMenu = ({setSelectedService}) => {
  const { metadata } = useSelector(state => state.metadata)
  const { isSubMenuOpen, coords, menu } = useSelector(state => state.subMenu)
  const dispatch = useDispatch()

  const displaySubMenu = (e) => {
    const tempBtn = e.target.getBoundingClientRect()
      const center = (tempBtn.left + tempBtn.right)/2
      const bottom = tempBtn.bottom 
      const top = tempBtn.top
      dispatch(openSubMenu({ coords: { center, bottom, top }, menu: 'dashboard' }))
  }

  const subMenuDisplay = useCallback(displaySubMenu,[coords])

  const removeSubMenu = () => {
    dispatch(closeSubMenu())
  }

  return (
    <div className="nm" onMouseEnter={subMenuDisplay} onMouseLeave={removeSubMenu} >
      { metadata?.user_metadata?.isServiceProvider ? 
      <SubContainer>
          <p className='nav__link' >Service Provider</p>
          { isSubMenuOpen ? <MdArrowDropUp size={20} />: <MdArrowDropDown size={20} />}
      </SubContainer> : null}
      <div className={`${isSubMenuOpen ? 'dsubmenu__fadein' : 'dsubmenu__hide'}`}>
        { (isSubMenuOpen && menu==='dashboard') ? <SubMenu setSelectedService={setSelectedService} /> : null }
      </div>
    </div>
  )
}



const NavBar = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const { authCheck: { authZero, firebase } } = useSelector(state => state.authCheck)
  const { isBookingUpdated } = useSelector(state => state.bookingUpdate)
  const { metadata, fetched } = useSelector(state => state.metadata)
  const [fbuser, loading, error] = useAuthState(auth);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [selectedService, setSelectedService] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    const checkFirebaseState = () => {
      try {
        const token = fbuser.accessToken 
        dispatch(updateFirebaseCheck(true ))
      } catch (error) {
        dispatch(updateFirebaseCheck(true))
      }
    }

    if (!loading) {
      checkFirebaseState()
    }
  },[loading])

  useEffect(() => {
    const checkAuth0State = async() => {
      try {
        await getAccessTokenSilently() 
        // dispatch(addMetadata(user))
      } catch (error) {
        dispatch(updateAuth0Check({ complete: true, isServiceProvider: false } ))
      }
    }

    checkAuth0State()
  },[])

  useEffect(() => {
    if (user) {
        dispatch(addMetadata(user))
    } 
  },[user])

  useEffect(() => {
    const getBooking = async() => {
      try {
        if (fbuser || isBookingUpdated) {
          const token = await fbuser.getIdToken()
          dispatch(fetchBookingDetail(fbuser.email,token))
        }
      } catch (error) {
        console.log(error);
      }
    }
    getBooking()
  },[fbuser, isBookingUpdated])

  useEffect(() => {
    if (fetched) {
      if (metadata?.user_metadata?.isServiceProvider) {
        dispatch(updateAuth0Check({ complete: true, isServiceProvider: true }))
      } else {
        dispatch(updateAuth0Check({ complete: true, isServiceProvider: false }))
      }
    }
  },[fetched, metadata])
  
  return (
    <section className="nav" >
      <div className="nav__container" style={{ width: !(fbuser || isAuthenticated) ? '100%': '90%' }} >
        <div className='nav__logo-container' onClick={() => {
          setSelectedService(false)
          history.push('/')
          }}>
          <img className='nav__logo' src='/notif-icon.webp' alt="nav__icon" width='30px' height='28px' />
          <Brand >TapAService </Brand>
        </div>
       <div style={{ display: 'flex', alignItems: 'center', gap: '15px'}}>
       { fbuser ? <ClientMenu isDropDownOpen={isDropDownOpen} setIsDropDownOpen={setIsDropDownOpen}/> :
         <>
          <ButtonsContainer>
            <ServicesLink onClick={() => {
            setSelectedService('about')
            history.push('/about-us')}}
            className={selectedService === 'about' ? 'nav__selected': ''}>About Us</ServicesLink>
            <ServicesLink onClick={() => {
            setSelectedService('subs')
            history.push('/subscriptions')}}
            className={selectedService === 'subs' ? 'nav__selected': ''}>Subscriptions</ServicesLink>
          <ServicesLink onClick={() => {
            setSelectedService('services')
            history.push('/services')}}
            className={selectedService === 'services' ? 'nav__selected': ''}>Services</ServicesLink>
          </ButtonsContainer>
          { isDropDownOpen ? <Close onClick={() => setIsDropDownOpen(prev => !prev)} size={20} /> : <Hamburger size={20} onClick={() => setIsDropDownOpen(prev => !prev)} />}
         </> }
         { isDropDownOpen && !fbuser ? <Dropdown setIsDropDownOpen={setIsDropDownOpen} /> :
          isDropDownOpen ? <UserDropdown setIsDropDownOpen={setIsDropDownOpen} /> : null }
        <div className='nav__auth-container'>
          { (authZero.complete && firebase) ? (
                <div className="nav__sub">
                  { isAuthenticated ? (
                    <div className='nav__image' >
                      <img className='nav__img' src={user.picture} alt="nav__icon" />
                    </div>
                  ) : fbuser ? (
                    <div onClick={() => history.push('/account',{ clientAccount: true } )} className='nav__image' >
                      <img className='nav__img' src={fbuser.photoURL} alt="nav__icon" />
                    </div>
                  ) : null }
                    <AuthButton setSelectedService={setSelectedService} color='black'/>
                </div>
              ) : <Skeleton height={25} width={300} baseColor='	#686868'  />}
        </div>
       </div>
          
      </div>
        { (authZero.complete && firebase && authZero.isServiceProvider) ? <NavMenu setSelectedService={setSelectedService} /> : null }
    </section>
  );
};

export default NavBar;
