import React from 'react'
import {MdOutlineError} from 'react-icons/md'
import { Container, Text } from './pay-success'


function PaymentError() {
  return (
    <Container>
        <MdOutlineError color='red' size={50} />
        <Text>Payment Error</Text>
    </Container>
  )
}

export default PaymentError