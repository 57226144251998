import React from "react"
import useNetwork from "../../utils/useNetwork"

export const OnlineStatus = React.createContext()

const OnlineStatusProvider = ({ children }) => {
    const { online } = useNetwork()

    return (
        <OnlineStatus.Provider value={online}>
            { children }
        </OnlineStatus.Provider>
    )
}

export default OnlineStatusProvider