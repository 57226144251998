import React from 'react'
import { useHistory } from 'react-router-dom'

export const FeaturesWithImages = React.memo(({features}) => {
    const history = useHistory()
  return (
    <div className='fwimage'>
        {features.map((feature,index) => {
            const { link, img } = feature
            return (
                <article onClick={() =>link && history.push(link)} style={{...(link && { cursor: 'pointer'})}} key={index} className="fwimage__feature">
                    <img className="fwimage__img" src={img} alt="feature-icon" loading="lazy"/>
                </article>
            )
        })}
  </div>
  )
})

export const InformativeFeature = React.memo(({icon,text,heading}) => {
    return (
        <div className='info__feature'>
          <div className='info__h' >
            <i className="info__i fa fa-2x">{icon}</i>
            <h3 className='info__h3'> {heading} </h3>
          </div>
          <p className='info__p'>{text}</p>
        </div>
    )
})


