import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  deleting : false,
  towerDelete: false,
  towerDeleteError: ''
}

const towerDelete = createSlice({
  name: 'towerDelete',
  initialState,
  reducers: {
    deleteTowerRequest(state) {
      state.deleting = true
    },
    deleteTowerSuccess(state, action) {
        state.deleting = false
        state.towerDelete = true
    },
    deleteTowerError(state, action) {
      state.deleting = false
      state.towerDeleteError = action.payload
    },
    deleteTowerReset(state) {
      state.deleting = false
      state.towerDeleteError = ''
      state.towerDelete = false
    },
  }
})

export const { deleteTowerRequest, deleteTowerSuccess, deleteTowerError, deleteTowerReset } = towerDelete.actions

export const deleteTower = (data,id,token) => async (dispatch) => {
  dispatch(deleteTowerRequest())
  try {
      await customAxios.delete('/towers',{ data },{ params: { id },
                                                    headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(deleteTowerSuccess(true))
  } catch (error) {
      dispatch(deleteTowerError(error.message))
  }      
}

export default towerDelete.reducer
