import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  creating : false,
  created: false,
  createAccountDeletionError: ''
}

const createAccountDeletion = createSlice({
  name: 'createAccountDeletion',
  initialState,
  reducers: {
    createAccountDeletionRequest(state) {
      state.creating = true
    },
    createAccountDeletionSuccess(state, action) {
        state.creating = false
        state.created = true
    },
    createAccountDeletionError(state, action) {
      state.creating = false
      state.createAccountDeletionError = action.payload
    },
    createAccountDeletionReset(state) {
      state.creating = false
      state.createAccountDeletionError = ''
      state.created = false
    },
  }
})

export const { createAccountDeletionRequest, createAccountDeletionSuccess, createAccountDeletionError, createAccountDeletionReset } = createAccountDeletion.actions

export const submitAccountDeletionRequest = (data,id,token) => async (dispatch) => {
    
  try {
      dispatch(createAccountDeletionRequest())
      await customAxios.post('/deletion-requests/account',data, { params: { id },
      headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(createAccountDeletionSuccess())
  } catch (error) {
      dispatch(createAccountDeletionError(error))
  }
}

export default createAccountDeletion.reducer
