import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  creating : false,
  created: false,
  createProfileDeletionError: ''
}

const createProfileDeletion = createSlice({
  name: 'createProfileDeletion',
  initialState,
  reducers: {
    createProfileDeletionRequest(state) {
      state.creating = true
    },
    createProfileDeletionSuccess(state, action) {
        state.creating = false
        state.created = true
    },
    createProfileDeletionError(state, action) {
      state.creating = false
      state.createProfileDeletionError = action.payload
    },
    createProfileDeletionReset(state) {
      state.creating = false
      state.createProfileDeletionError = ''
      state.created = false
    },
  }
})

export const { createProfileDeletionRequest, createProfileDeletionSuccess, createProfileDeletionError, createProfileDeletionReset } = createProfileDeletion.actions

export const submitProfileDeletionRequest = (data,id,token) => async (dispatch) => {
    
  try {
      dispatch(createProfileDeletionRequest())
      await customAxios.post('/deletion-requests/profile',data, { params: { id },
      headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(createProfileDeletionSuccess())
  } catch (error) {
      dispatch(createProfileDeletionError(error))
  }
}

export default createProfileDeletion.reducer
