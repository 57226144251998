import React from 'react'
import styled from 'styled-components'

import ManageAccount from '../Components/dashboard/views/ManageAccount'

const Container = styled.div`
padding: 20px 10%;
`

function Account() {
  return (
    <Container>
        <ManageAccount isClientAccount={true} />
    </Container>
  )
}

export default Account