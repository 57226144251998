import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import App from './App'
import store from './redux/store'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store} >
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Provider>
);

