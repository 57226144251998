import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isBookingProgressSent: false,
    bookingProgressError: '',
    booking: null
}

const bookingProgress = createSlice({
  name: 'bookingProgress',
  initialState,
  reducers: {
    bookingProgressSuccess(state, action) {
        state.isBookingProgressSent = true
        state.booking = action.payload
    },
    resetBookingProgress(state) {
        state.isBookingProgressSent = false
        state.booking = null
    },
  }
})

export const { bookingProgressSuccess, resetBookingProgress } = bookingProgress.actions

export default bookingProgress.reducer