import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyBb8PyDL4TFqM5UU_hom9PIRJwYlEIdffI",
    authDomain: "tapaservice.firebaseapp.com",
    // authDomain: "tapaservice.co.za",
    projectId: "tapaservice",
    storageBucket: "tapaservice.appspot.com",
    messagingSenderId: "888829198586",
    appId: "1:888829198586:web:2a58dc83a3f76d90484943",
    measurementId: "G-MH0SJEC0ZY"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;