import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../../redux/axios/axios'

const initialState = {
  creating : false,
  created: false,
  createProfileVerificationError: ''
}

const verificationRequest = createSlice({
  name: 'verificationRequest',
  initialState,
  reducers: {
    verificationRequesting(state) {
      state.creating = true
    },
    verificationRequestSuccess(state, action) {
        state.creating = false
        state.created = true
    },
    verificationRequestError(state, action) {
      state.creating = false
      state.createProfileVerificationError = action.payload
    },
    verificationRequestReset(state) {
      state.creating = false
      state.createProfileVerificationError = ''
      state.created = false
    },
  }
})

export const { verificationRequesting, verificationRequestSuccess, verificationRequestError, verificationRequestReset } = verificationRequest.actions

export const createProfileVerification = (data,id,token) => async (dispatch) => {
  try {
      dispatch(verificationRequesting())
      await customAxios.post('/profile/verification', data, { params: { id },
                                                              headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(verificationRequestSuccess())
  } catch (error) {
      dispatch(verificationRequestError(error))
  }
}

export default verificationRequest.reducer
