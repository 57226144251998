import { createSlice } from "@reduxjs/toolkit"

const progressBar = createSlice({
  name: 'progressBar',
  initialState: { fileUploadsProgress: [] },
  reducers: {
    updateFiles(state, action) {
      state.fileUploadsProgress = action.payload.Items
    },
    updateProgressBar(state, action) {
        const { name, percentage } = action.payload
        const filteredItems = state.fileUploadsProgress.filter(item => item.name !== name)
        const updatedProgress = [...filteredItems, { name, percentage }]
      state.fileUploadsProgress = updatedProgress
    },
    resetProgressBar(state){
      state.fileUploadsProgress = []
    }
  }
})

export const { updateFiles, updateProgressBar, resetProgressBar } = progressBar.actions

export default progressBar.reducer