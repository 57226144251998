import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  booking: null,
  bookingError: ''
}

const booking = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    fetchedBookingRequest(state) {
      state.loading = true
    },
    fetchedBookingSuccess(state, action) {
        state.loading = false
        state.booking = action.payload
    },
    fetchedBookingError(state, action) {
      state.loading = false
      state.bookingError = action.payload
    },
    fetchedBookingReset(state) {
      state.loading = false
      state.bookingError = ''
      state.booking = null
    },
  }
})

export const { fetchedBookingRequest, fetchedBookingSuccess, fetchedBookingError, fetchedBookingReset } = booking.actions

export const fetchBookingDetail = (clientEmail, token) => async (dispatch) => {
  dispatch(fetchedBookingRequest())
  try {
      const { data: { Items, Count }} = await customAxios.get('/booking',{ params: { clientEmail }, headers: { 'Authorization': `Bearer ${token}` } });
      !Count && dispatch(fetchedBookingSuccess(null))
      if (Count) {
          const { state } = Items[0]
          if (state !== 'COLLECTED') {
              dispatch(fetchedBookingSuccess(Items[0]))
          } else {
              dispatch(fetchedBookingSuccess(null))
          }
      }
  } catch (error) {
      dispatch(fetchedBookingError(error.message))
  }      
}

export const bookingDetailReducer = booking.reducer
