import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  payments: [],
  paymentsError: '',
  lastEvaluatedKey: null
}

const payments = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    fetchPaymentsRequest(state) {
      state.loading = true
    },
    fetchedPaymentsSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.payments = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMorePaymentsSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.payments = [...state.payments,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
    },
    fetchedPaymentsError(state, action) {
        state.loading = false
        state.paymentsError = action.payload
      },
  }
})

export const { fetchPaymentsRequest, fetchedMorePaymentsSuccess,
        fetchedPaymentsError, fetchedPaymentsSuccess } = payments.actions

export const fetchPayments = (params, companyID, q, startDate, endDate, id, token) => async (dispatch) => {
    dispatch(fetchPaymentsRequest())
    try {
        const { data } = await customAxios.post('/payments',{ companyID, q,  startDate: startDate?.getTime(), endDate: endDate?.getTime() },{ params: {...params, id},
        headers: {
            Authorization: `Bearer ${token}`
            }});
        const { Items, LastEvaluatedKey} = data
        dispatch(fetchedPaymentsSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedPaymentsError(error.message))
    }      
}

export const paymentsPaginationUpdate = (params, companyID, q, startDate, endDate, id, token) => async (dispatch) => {
    try {
        const { data } = await customAxios.post('/payments',{ companyID, q,  startDate: startDate?.getTime(), endDate: endDate?.getTime() },{ params: {...params, id},
                                                                                        headers: {
                                                                                            Authorization: `Bearer ${token}`
                                                                                            }});
        const { Items, LastEvaluatedKey } = data
        dispatch(fetchedMorePaymentsSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedPaymentsError(error.message))
    }
}

export default payments.reducer