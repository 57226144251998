import { createSlice } from "@reduxjs/toolkit"
import customAxios from '../../redux/axios/axios'

const initialSelectedCategoryState = {
  selectedCategory: null
}

const selectedCategory = createSlice({
name: 'selectedCategory',
initialState: initialSelectedCategoryState,
reducers: {
  setSelectedCategory(state, action) {
      state.selectedCategory = action.payload
  }
}
})

export const { setSelectedCategory } = selectedCategory.actions
export const selectedCategoryReducer = selectedCategory.reducer

const initialState = {
    loading : false,
    profiles: [],
    profilesError: '',
    lastEvaluatedKey: null,
    loadingMore: false
}

const servicesProfiles = createSlice({
  name: 'servicesProfiles',
  initialState,
  reducers: {
    fetchServicesProfilesRequest(state) {
      state.loading = true
    },
    fetchMoreServicesProfilesRequest(state) {
      state.loadingMore = true
    },
    fetchedServicesProfilesSuccess(state, action) {
        const { Items, LastEvaluatedKey } = action.payload
        state.profiles = Items
        state.lastEvaluatedKey = LastEvaluatedKey
        state.loading = false
    },
    fetchedMoreServicesProfilesSuccess(state, action) {
      const { Items, LastEvaluatedKey } = action.payload
      state.profiles = [...state.profiles,...Items]
      state.lastEvaluatedKey = LastEvaluatedKey
      state.loadingMore = false
    },
    fetchedServicesProfilesError(state, action) {
        state.loading = false
        state.loadingMore = false
        state.profilesError = action.payload
      },
  }
})

const { fetchServicesProfilesRequest, fetchedServicesProfilesSuccess,
                fetchedMoreServicesProfilesSuccess, fetchedServicesProfilesError,
                fetchMoreServicesProfilesRequest } = servicesProfiles.actions

export const fetchProfiles = (postData) => async (dispatch) => {
    dispatch(fetchServicesProfilesRequest())
    try {
        const { data } = await customAxios.post('/services-list',{...postData});
        const { Items, LastEvaluatedKey} = data
        dispatch(fetchedServicesProfilesSuccess({ Items, LastEvaluatedKey }))
    } catch (error) {
        dispatch(fetchedServicesProfilesError(error.message))
    }      
}

export const paginationUpdate = (postData) => async (dispatch) => {
    try {
        dispatch(fetchMoreServicesProfilesRequest())
        const { data } = await customAxios.post('/services-list',{...postData});
        const { Items, LastEvaluatedKey } = data
        dispatch(fetchedMoreServicesProfilesSuccess({ Items, LastEvaluatedKey}))
    } catch (error) {
        dispatch(fetchedServicesProfilesError(error.messsage))
    }
}

export default servicesProfiles.reducer