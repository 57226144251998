import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

import customAxios from '../../redux/axios/axios'

const initialState = {
  fetching: true,
  metadata: {},
  metadataError: '',
  fetched: false
}

const metadata = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    addMetadataRequest(state) {
      state.fetching = true
    },
    addMetadataSuccess(state, action) {
        state.fetching = false
        state.metadata = action.payload
        state.fetched = true
    },
    addMetadataError(state, action) {
      state.fetching = false
      state.metadataError = action.payload
      state.fetched = true
    },
    addMetadataReset(state) {
      state.fetching = false
      state.metadataError = ''
      state.metadata = {}
      state.fetched = false
    },
  }
})

export const { addMetadataRequest, addMetadataSuccess, addMetadataError, addMetadataReset } = metadata.actions

export const addMetadata = (user) => async (dispatch) => { 
  dispatch(addMetadataRequest())
  const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
    try {
      const { data: accessToken } = await customAxios.get('/management-api-token')
      const { data } = await axios.get(userDetailsByIdUrl,{ headers: { Authorization: `Bearer ${accessToken}` }})
      dispatch(addMetadataSuccess(data))
    } catch (error) {
      console.log(error);
      dispatch(addMetadataError(error))
    } 
}

export default metadata.reducer
