/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      channelID
      author
      receiver
      body
      senderName
      read
      type
      createdAt
      updatedAt
      longitude
	    latitude
	    dateTime
      transactionReferenceID
      connID
      paymentMethod
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      channelID
      author
      receiver
      body
      senderName
      read
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      channelID
      author
      receiver
      body
      senderName
      read
      type
      createdAt
      updatedAt
    }
  }
`;
